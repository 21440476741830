export const formatToPattern = (value: string, pattern: string): string => {
  const maskLength = [...pattern.matchAll(/#/g)].length
  if (maskLength !== value.length) return value

  let i = 0
  return pattern.replace(/#/g, (): string => {
    i += 1
    return value[i - 1]
  })
}

export const cellphonePattern = (cellphone: string): string =>
  formatToPattern(cellphone, '(##) #####-####')

export const cpfPattern = (cpf: string): string => formatToPattern(cpf, '###.###.###-##')

export const datePattern = (date: string): string =>
  formatToPattern(date.replace(/-/g, ''), '##/##/####')

// TODO: move to Lib Util project
export const currencyMask = (
  inputValue: string | undefined,
  currency: 'BRL' | 'USD' | undefined,
) => {
  if (!inputValue) return ''

  const result = Number.isNaN(+inputValue) ? inputValue : Number(inputValue).toFixed(2)
  const floatValue = parseFloat(result.replace(/[^\d]/g, '').replace(/(\d\d?)$/, '.$1'))

  const str = floatValue
    .toLocaleString('pt-BR', { style: 'currency', currency: currency || 'BRL' })
    .replace('', '')

  if (parseFloat(str.replace(',', '.')) === 0) {
    return ''
  }

  return str.trim()
}
