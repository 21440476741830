import styled from 'styled-components';
interface TextProps {
  fontWeight?: string;
  fontSize?: string;
  color?: string;
}

export const Text = styled.span<TextProps>`
  font-family: 'Inter';
  line-height: 1.063rem;
  text-align: left;

  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight}`};
  ${(props) => props.fontSize && `font-size: ${props.fontSize}`};
  ${(props) => props.color && `color: ${props.color}`};
`;

export const BabiContainer = styled.div`
  display: flex;
  border: ${(props) => `1px solid ${props.theme.colors.neutral.grayscale.A200}`};
  border-radius: 8px;
  width: 100%;
  height: 100%;
  max-height: 90px;
  padding: 0 16px;
  box-sizing: border-box;
`;

export const WrapperImg = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-bottom: 0px;
  img {
    max-height: 90px;
  }
`;

export const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  justify-content: center;
  width: 65%;

  span {
    margin-bottom: 2px;
    font-size: 14px;

    &:last-child {
      font-size: 12px;
      color: #6a6c72;
    }
  }
`;

export const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: center;
  justify-self: flex-end;
`;

export const StoryBookContainer = styled.div`
  width: 100%;
  max-width: 420px;
`;
