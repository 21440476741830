import { Theme } from '@/common/types/theme'

export const getCurrentTheme = (): Theme =>
  (document.documentElement.getAttribute('theme') as Theme) || ('' as Theme)

export const darkModeEnabled = (): boolean => getCurrentTheme().includes('dark')

export const enableDarkMode = (): void => {
  if (darkModeEnabled()) return
  document.documentElement.setAttribute('theme', `${getCurrentTheme()} dark`)
}

export const disableDarkMode = (): void => {
  if (!darkModeEnabled()) return
  document.documentElement.setAttribute('theme', getCurrentTheme().replace('dark', '').trim())
}

export const toggleDarkMode = (): void => (darkModeEnabled() ? disableDarkMode() : enableDarkMode())

export const changeTheme = (theme: Theme): void => {
  const newTheme = darkModeEnabled() ? `${theme} dark` : theme
  return document.documentElement.setAttribute('theme', newTheme)
}

export const initTheme = (theme: Theme = Theme.PF, darkMode?: boolean): void =>
  changeTheme(darkMode ? (`${theme} dark` as Theme) : theme)
