import React, { useEffect, useCallback, useState } from 'react';
import { useSwipeable, SwipeEventData } from 'react-swipeable';
import { IInterUIBottomSheetProps } from '../../interfaces/inter-ui-bottom-sheet-props';
import {
  Content,
  Handler,
  Header,
  Link,
  Overlay,
  Wrap
} from './InterUIBottomSheet.styles';

/**
 * Componente Inter UI Bottom Sheet.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUIBottomSheet: React.FC<
  IInterUIBottomSheetProps & React.HTMLAttributes<HTMLDivElement>
> = ({
  title,
  children,
  toggle,
  onHide,
  link,
  backgroundColor,
  onClickedLink,
  className,
  ...props
}) => {
  const [position, setPosition] = useState<number>(0);

  const contentRef = React.createRef<HTMLDivElement>();
  const limitDown = 20;

  let isDown = false;
  let posTranslate = 0;

  const shouldClose = useCallback(
    ({ dir, deltaY }: SwipeEventData, finished = false) => {
      if (dir === 'Up' && deltaY < -1) {
        changePosition(0, contentRef.current);

        return;
      } else if (dir !== 'Down') return;

      changePosition(deltaY, contentRef.current);

      if (contentRef.current && finished && deltaY > 30) {
        contentRef.current.removeAttribute('style');
        onHide();
      }
    },
    [onHide, position]
  );

  const changePosition = (position: number, currentDom: HTMLElement | null) => {
    setPosition(position);

    if (currentDom) {
      currentDom.style.transform = `translate3d(0, ${position}%, 0)`;
      currentDom.style.transition = 'all 0.5s ease 0s';
    }
  };

  const { ref } = useSwipeable({
    onSwiping: shouldClose,
    onSwiped: (eventData) => shouldClose(eventData, true),
    preventDefaultTouchmoveEvent: true,
    delta: 10
  });

  useEffect(() => {
    if (toggle) {
      document.body.setAttribute('style', 'overflow:hidden');
    } else {
      document.body.removeAttribute('style');
    }
  }, [toggle]);

  /**
   * Evento mouse down para definir que o usuário iniciou a tentativa
   * de fechar o bottom sheet.
   */
  const onMouseDown = () => {
    isDown = true;
  };

  /**
   * Evento mouse up para validar o posicionamento do handler e ocultar
   * o bottom sheet.
   */
  const onMouseUp = () => {
    isDown = false;

    if (contentRef.current) {
      contentRef.current.removeAttribute('style');
    }

    if (posTranslate > limitDown) {
      onHide();
    }

    posTranslate = 0;
  };

  /**
   * Evento mouse move para realizar a manipulação visual do handler e
   * ocultar o bottom sheet.
   */
  const onMouseMove = () => {
    if (contentRef.current) {
      const target = contentRef.current;

      if (isDown) {
        posTranslate = posTranslate + 1;

        if (posTranslate > limitDown) {
          contentRef.current.removeAttribute('style');

          onHide();
        } else {
          setTranslate3D(target);
        }
      } else {
        target.removeAttribute('style');
      }
    }
  };

  /**
   * Adicionar style inline com o posicionamento atual do handler.
   * @param target Target ao qual será adicionado o style.
   */
  const setTranslate3D = (target: HTMLDivElement) => {
    target.style.transform = `translate3d(0, ${posTranslate}%, 0)`;
    target.style.transition = 'none 0s ease 0s';
  };

  return (
    <React.Fragment>
      <Overlay toggle={toggle} onClick={onHide} onMouseUp={onMouseUp} />

      <Content
        ref={contentRef}
        toggle={toggle}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        backgroundColor={backgroundColor}
        role='dialog'
        data-testid='content-bottomsheet-test'
        className={'inter-ui-bottomsheet-content ' + (className || '')}
        {...props}
      >
        <Handler
          data-testid='handler-bottomsheet-test'
          onMouseDown={onMouseDown}
          ref={ref}
        />

        {(title || link) && (
          <Header>
            {(title || link) && <h3>{title}</h3>}

            {link && (
              <Link role='link' onClick={onClickedLink}>
                {link}
              </Link>
            )}
          </Header>
        )}

        <Wrap data-testid='children-bottomsheet-test'>{children}</Wrap>
      </Content>
    </React.Fragment>
  );
};
