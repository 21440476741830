import React from 'react';
import { useTheme } from 'styled-components';
import { InterUIIcon } from '../../core/InterUIIcon/InterUIIcon';
import {
  ErrorContentProps,
  FilledContentProps,
  IInterUIAttachmentCardProps,
  LoadingContentProps,
  NewContentProps,
  SuccessContentProps
} from '../../interfaces/inter-ui-attachment-card-props';
import { InterUIFileInput } from '../InterUIFileInput/InterUIFileInput';
import { InterUILoading } from '../InterUILoading/InterUILoading';
import { InterUIProgressBar } from '../InterUIProgressBar/InterUIProgressBar';
import {
  ActionsContainer,
  Container,
  Content,
  Filename,
  FlexRow,
  ImagePlaceholder,
  ImagePreview,
  Subtitle,
  TextContent,
  Title,
  TitleSimple
} from './InterUIAttachmentCard.styles';

const NewContent: React.FC<NewContentProps> = ({
  subtitle,
  onAttach,
  onError,
  compress,
  sizeLimitValue,
  acceptedFileTypes
}) => {
  const theme = useTheme();

  return (
    <InterUIFileInput
      className='inter-ui-file-input'
      onAttach={onAttach}
      onError={onError}
      hasCompressor={compress}
      limitFileSize={compress}
      sizeLimitValue={sizeLimitValue}
      acceptedFileTypes={acceptedFileTypes}
      clearValue
      accept='.png, .jpg, .jpeg, .pdf, .docx'
    >
      <FlexRow>
        <ImagePlaceholder data-testid='image-placeholder' />
        <TextContent>
          <Title>Adicionar arquivos</Title>
          <Subtitle>{subtitle || 'Arquivos PDF, JPEG, PNG ou DOCX.'}</Subtitle>
        </TextContent>
        <ActionsContainer>
          <InterUIIcon
            name='attachment'
            size='md'
            color={theme.colors.primary.A500}
          />
        </ActionsContainer>
      </FlexRow>
    </InterUIFileInput>
  );
};

const NewSimpleContent: React.FC<NewContentProps> = ({
  onAttach,
  onError,
  compress,
  sizeLimitValue,
  acceptedFileTypes
}) => {
  const theme = useTheme();

  return (
    <InterUIFileInput
      className='inter-ui-file-input'
      onAttach={onAttach}
      onError={onError}
      clearValue
      accept='.png, .jpg, .jpeg, .pdf, .docx'
      hasCompressor={compress}
      limitFileSize={compress}
      sizeLimitValue={sizeLimitValue}
      acceptedFileTypes={acceptedFileTypes}
    >
      <FlexRow>
        <TextContent>
          <TitleSimple data-testid='title-newSimple'>
            Anexar arquivos
          </TitleSimple>
        </TextContent>
        <ActionsContainer>
          <InterUIIcon
            name='attachment'
            size='md'
            color={theme.colors.primary.A500}
          />
        </ActionsContainer>
      </FlexRow>
    </InterUIFileInput>
  );
};

const LoadingContent: React.FC<LoadingContentProps> = ({
  progress,
  onCancel
}) => {
  const theme = useTheme();
  return (
    <FlexRow>
      <ImagePlaceholder data-testid='image-placeholder' />
      <Content>
        <FlexRow>
          <TextContent>
            <Title>Anexando imagem</Title>
            <Subtitle>{progress}% - Por favor aguarde</Subtitle>
          </TextContent>
          <ActionsContainer>
            <InterUILoading
              halfColor={theme.colors.neutral.grayscale.A400}
              fullColor={theme.colors.neutral.grayscale.A200}
            />
            <InterUIIcon
              onClick={onCancel}
              name='contextual-error'
              size='md'
              color={theme.colors.feedback.error.A500}
            />
          </ActionsContainer>
        </FlexRow>
        <InterUIProgressBar
          progress={`${progress}%`}
          height='6px'
          rounded
          barColor={theme.colors.neutral.grayscale.A200}
        />
      </Content>
    </FlexRow>
  );
};

const SuccessContent: React.FC<SuccessContentProps> = ({ subtitle, size }) => {
  const theme = useTheme();

  return (
    <FlexRow>
      <ImagePlaceholder data-testid='image-placeholder' />
      <Content>
        <FlexRow>
          <TextContent>
            <Title>Sucesso!</Title>
            <Subtitle marginBottom={size ? '2px' : '8px'}>
              {subtitle || 'Imagem anexada.'}
            </Subtitle>
            {size && <Subtitle>{size}</Subtitle>}
          </TextContent>
          <ActionsContainer>
            <InterUIIcon
              name='contextual-success'
              size='md'
              color={theme.colors.feedback.success.A500}
            />
          </ActionsContainer>
        </FlexRow>
        <InterUIProgressBar
          progress='100%'
          height='6px'
          rounded
          progressColor={theme.colors.feedback.success.A500}
        />
      </Content>
    </FlexRow>
  );
};

const ErrorContent: React.FC<ErrorContentProps> = ({
  onAttach,
  onCancel,
  onError,
  compress,
  acceptedFileTypes
}) => {
  const theme = useTheme();
  return (
    <FlexRow>
      <ImagePlaceholder data-testid='image-placeholder' />
      <Content>
        <FlexRow>
          <TextContent>
            <Title>Erro</Title>
            <Subtitle>Tente anexar novamente.</Subtitle>
          </TextContent>
          <ActionsContainer>
            <InterUIFileInput
              onAttach={onAttach}
              onError={onError}
              accept='.png, .jpg, .jpeg, .pdf, .docx'
              hasCompressor={compress}
              limitFileSize={compress}
              acceptedFileTypes={acceptedFileTypes}
            >
              <InterUIIcon
                name='refresh'
                size='md'
                color={theme.colors.primary.A500}
              />
            </InterUIFileInput>
            <InterUIIcon
              onClick={onCancel}
              name='contextual-error'
              size='md'
              color={theme.colors.feedback.error.A500}
            />
          </ActionsContainer>
        </FlexRow>
        <InterUIProgressBar
          progress='100%'
          height='6px'
          rounded
          progressColor={theme.colors.feedback.error.A500}
        />
      </Content>
    </FlexRow>
  );
};

const FilledContent: React.FC<FilledContentProps> = ({
  imageSrc,
  fileName,
  subtitle,
  size,
  onRemove
}) => {
  const theme = useTheme();
  return (
    <FlexRow>
      {imageSrc ? (
        <ImagePreview src={imageSrc} alt='attachment preview' />
      ) : (
        <ImagePlaceholder data-testid='image-placeholder' />
      )}
      <TextContent>
        <Title>{fileName}</Title>
        <Subtitle marginBottom={size ? '2px' : '8px'}>
          {subtitle || 'Imagem anexada.'}
        </Subtitle>
        {size && <Subtitle>{size}</Subtitle>}
      </TextContent>
      <ActionsContainer>
        <InterUIIcon
          onClick={onRemove}
          name='trash'
          size='md'
          color={theme.colors.primary.A500}
        />
        <InterUIIcon
          name='contextual-success'
          size='md'
          color={theme.colors.feedback.success.A500}
        />
      </ActionsContainer>
    </FlexRow>
  );
};

const FilledContentSimple: React.FC<FilledContentProps> = ({
  fileName,
  onRemove
}) => {
  const theme = useTheme();
  return (
    <FlexRow>
      <TextContent>
        <Subtitle marginBottom='4px'>Arquivo anexado.</Subtitle>
        <Filename>{fileName}</Filename>
      </TextContent>
      <ActionsContainer>
        <InterUIIcon
          onClick={onRemove}
          name='trash'
          size='md'
          color={theme.colors.primary.A500}
        />
      </ActionsContainer>
    </FlexRow>
  );
};

export const InterUIAttachmentCard: React.FC<
  IInterUIAttachmentCardProps & React.HtmlHTMLAttributes<HTMLElement>
> = ({
  status = 'new',
  fileName = '',
  imageSrc = '',
  subtitle,
  size,
  onAttach = () => { },
  onRemove = () => { },
  onCancel = () => { },
  onError = () => { },
  progress,
  acceptedFileTypes,
  sizeLimitValue,
  ...props
}) => {
    const [filledName, setFilledName] = React.useState(fileName);
    const [imagePreview, setImagePreview] = React.useState(imageSrc);

    async function handleAttach(event: any, base64File?: string) {
      const files = event.target.files;

      if (files && files[0]) {
        const file = files[0];
        setFilledName(file.name);

        if (file.type.includes('image/') && base64File) {
          setImagePreview(base64File);
        }

        onAttach(event, base64File);
      } else {
        onError(event);
      }
    }

    return (
      <Container {...props}>
        {status === 'new' && (
          <NewContent
            subtitle={subtitle}
            onAttach={handleAttach}
            onError={onError}
            compress={props.compress}
            acceptedFileTypes={acceptedFileTypes}
          />
        )}
        {status === 'newSimple' && (
          <NewSimpleContent
            onAttach={handleAttach}
            onError={onError}
            compress={props.compress}
            sizeLimitValue={sizeLimitValue}
            acceptedFileTypes={acceptedFileTypes}
          />
        )}
        {status === 'loading' && (
          <LoadingContent progress={progress} onCancel={onCancel} />
        )}
        {status === 'success' && (
          <SuccessContent
            imageSrc={imagePreview}
            subtitle={subtitle}
            size={size}
          />
        )}
        {status === 'error' && (
          <ErrorContent
            onAttach={handleAttach}
            onCancel={onCancel}
            onError={onError}
            compress={props.compress}
            acceptedFileTypes={acceptedFileTypes}
          />
        )}
        {status === 'filled' && (
          <FilledContent
            subtitle={subtitle}
            size={size}
            fileName={filledName}
            imageSrc={imagePreview}
            onRemove={onRemove}
          />
        )}
        {status === 'filledSimple' && (
          <FilledContentSimple fileName={filledName} onRemove={onRemove} />
        )}
      </Container>
    );
  };
