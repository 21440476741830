import React from 'react';

import { IInterUIBoxProps } from '../../interfaces/inter-ui-box-props';
import { Box } from './InterUIBox.styles';

/**
 * Componente Inter UI Box.
 * @param props Propriedades disponíveis para exibição de informações no card.
 */
export const InterUIBox: React.FC<
  IInterUIBoxProps & React.HTMLAttributes<HTMLDivElement>
> = ({ children, ...props }) => {
  return (
    <Box data-testid='inter-ui-box' {...props}>
      {children}
    </Box>
  );
};
