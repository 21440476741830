import styled from 'styled-components';
import { JustifyContent } from '../../interfaces/inter-ui-commons-props';

export const Container = styled.label<{
  checked?: boolean;
  disabled?: boolean;
  margin?: string;
  justify?: JustifyContent;
}>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify || `space-between`};
  ${(props) => props.margin && `margin: ${props.margin};`};
`;

export const Content = styled.div<{
  disabled?: boolean;
}>`
  ${(props) =>
    props.disabled &&
    `&, * { color: ${props.theme.colors.neutral.grayscale.A300} }`};
`;

export const SwitchContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
`;

export const InputCheckboxHidden = styled.input.attrs({ type: 'checkbox' })`
  display: none;

  &:checked + span {
    background-color: ${(props) => props.theme.colors.primary.A500};
    :before {
      -webkit-transform: translateX(14px);
      -ms-transform: translateX(14px);
      transform: translateX(14px);
    }
  }

  &:disabled {
    + span {
      background-color: ${(props) => props.theme.colors.neutral.grayscale.A200};
    }
    &:checked + span {
      background-color: ${(props) => props.theme.colors.primary.A200};
    }
  }
`;

export const Switch = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.neutral.grayscale.A300};
  border-radius: 48px;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  :before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: ${(props) => props.theme.colors.theme};
    border-radius: 50%;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
`;
