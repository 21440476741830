import styled from 'styled-components';

import { IInterUIHeaderProps } from '../../interfaces/inter-ui-header-props';
import { PositionAbsoluteLeftRight } from '../../utils/inter-ui-providers';

const fontColor = (props: IInterUIHeaderProps) => props.fontColor || `inherit`;

/**
 * Div principal.
 */
export const Container = styled.header`
  ${(props: IInterUIHeaderProps) =>
    props.backgroundImage && `background-image: url(${props.backgroundImage});`}

  ${(props: IInterUIHeaderProps) =>
    !props.backgroundImage &&
    props.background &&
    `background: ${props.background};`}

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: ${(props: IInterUIHeaderProps) => props.height || `144px`};
  padding: 16px;

  ${PositionAbsoluteLeftRight('inter-ui-header__btn-circle')}
`;

/**
 * Título principal.
 */
export const MainTitle = styled.h1`
  font-size: 1.5rem;
  line-height: 1.625rem;
  color: ${fontColor};
  font-weight: 600;
`;

/**
 * Texto acima do título principal.
 */
export const TextSmall = styled.h3`
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 1rem;
  color: ${fontColor};
  font-weight: 400;
`;
