import styled from 'styled-components';

import { IInterUIContainerProps } from '../../interfaces/inter-ui-container-props';

/**
 * Div principal.
 */
export const Container = styled.div<{
  fullPage?: boolean;
  stickyFooter: boolean;
}>`
  ${(props) =>
    props.stickyFooter &&
    `
    display: flex;
    flex-direction: column;
    height: ${props.fullPage ? `100vh` : `calc(100vh - 56px)`};
    overflow: auto;
    `}
`;

/**
 * Div para o children.
 */
export const Content = styled.div<IInterUIContainerProps>`
  margin: ${(props) => props.margin || `24px`};
`;

/**
 * Div para o rodapé que ficara posicionado ao final da página e poderá descer de acordo com o scroll.
 */
export const StickyFooter = styled.div<IInterUIContainerProps>`
  margin: ${(props) => props.margin || `24px`};
  margin-top: auto;
`;
