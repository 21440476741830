import { createGlobalStyle } from 'styled-components'

// utils
import { gray, fontSizes, fontWeights, interGridBreakpoints } from './utils/variables'
import { generateColorClasses } from './utils/colors'
import { generateFontSizeClasses, generateFontWeightClasses } from './utils/fonts'

export default createGlobalStyle`
  ${generateColorClasses(gray, 'gray')}
  ${generateFontSizeClasses(fontSizes, interGridBreakpoints)}
  ${generateFontWeightClasses(fontWeights)}
`
