import styled, { css, keyframes } from 'styled-components';
import { IInterUISkeletonProps } from '../../interfaces/inter-ui-skeleton-props';

const wave = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -150% 0%;
  }
`;

const pulse = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`;

const animationModifiers = {
  wave: () => css`
    animation: ${wave} 3s ease-in-out infinite alternate;
  `,
  pulse: () => css`
    animation: ${pulse} 3s ease-in-out infinite;
  `
};

/**
 * Div principal
 */

export const Skeleton = styled.div<IInterUISkeletonProps>`
  position: relative;
  width: ${(props) => props.width || `100%`};
  height: ${(props) => props.height || `16px`};
  margin-bottom: ${(props) => props.marginBottom};
  border-radius: 6px;
  background: linear-gradient(
    -90deg,
    ${(props) => `${props.theme.colors.neutral.grayscale.A100}`} 0%,
    ${(props) => `${props.theme.colors.neutral.grayscale.A200}`} 50%,
    ${(props) => `${props.theme.colors.neutral.grayscale.A100}`} 100%
  );
  background-size: 400%;
  ${(props) =>
    props.animation !== 'disabled' &&
    animationModifiers[props.animation || 'wave']};

  // variant circle
  ${(props) => props.variant === 'circle' && `border-radius: 50%;`}
`;
