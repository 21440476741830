export interface IThemeColors {
  theme: string
  typography: string
}

export const themeLight: IThemeColors = {
  theme: '#FFFFFF',
  typography: '#161616',
}

export const themeDark: IThemeColors = {
  theme: '#1C1C1E',
  typography: '#F0F1F6',
}


