import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { InterUIIcon } from '../../core/InterUIIcon/InterUIIcon';
import { InterUIInputGroup } from '../../core/InterUIInputGroup/InterUIInputGroup';
import {
  IInterUIDropdownOption as DropdownOption,
  IInterUIDropdownProps
} from '../../interfaces/inter-ui-dropdown-props';
import { RemoveAccents } from '../../utils/inter-ui-providers';
import { InterUIBottomSheet } from '../InterUIBottomSheet/InterUIBottomSheet';
import { InterUILoading } from '../InterUILoading/InterUILoading';
import {
  Button,
  Container,
  ContentOptions,
  DropdownFake,
  Label,
  Loading,
  Option
} from './InterUIDropdown.styles';
import { InterUIButton, InterUICheckBox } from '../../core';

/**
 * Componente Inter UI Dropdown.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUIDropdown: React.FC<IInterUIDropdownProps> = ({
  margin,
  contentHeight,
  name,
  label,
  labelOptions = 'Selecione uma opção',
  buttonLabel = 'Confirmar',
  placeholder,
  value = null,
  options,
  disabled = false,
  onChange,
  hideSearch = false,
  showCloseLink = false,
  onFilter,
  helperButton,
  onClickHelper,
  loading,
  focusSearch,
  truncateOption = true,
  isMultiselect = false,
  ...props
}) => {
  const theme = useTheme();
  const searchRef = useRef<HTMLInputElement>(null);
  const [toggle, setToggle] = useState<boolean>(false);
  const [selected, setSelected] = useState<DropdownOption | DropdownOption[]>();
  const [filteredData, setFilteredData] = useState<DropdownOption[]>([]);
  const [emptySearch, setEmptySearch] = useState<boolean>(true);

  let debounce: NodeJS.Timeout;

  const setValue = useCallback(() => {
    if (isMultiselect) {
      const newSelectedOptions: DropdownOption[] = [];
      if (value) {
        (value as string[] | number[]).forEach((valueItem: string | number) => {
          const optionFounded = options.find(
            (item) => item.value === valueItem
          );

          if (optionFounded) {
            newSelectedOptions.push(optionFounded);
          }
        });
      }
      if (newSelectedOptions.length > 0) setSelected(newSelectedOptions);
      else setSelected(undefined);
    } else {
      setSelected(options.find((item) => item.value === value));
    }
  }, [isMultiselect, value, options]);

  /**
   * Apaga valor do input de search e limpa os dados do filtro.
   */
  const deleteSearchValue = () => {
    setEmptySearch(true);
    setFilteredData(options);

    if (searchRef.current) {
      searchRef.current.value = '';
    }
  };

  /**
   * Exibir o bottom sheet com as opções somente se o dropdown não estiver desabilitado.
   */
  const showOptions = () => setToggle(!disabled);

  /**
   * Ocultar o bottom sheet com as opções.
   */
  const hideOptions = () => {
    setToggle(false);

    setTimeout(() => {
      deleteSearchValue();
    }, 500);
  };

  /**
   * Evento disparado ao clicar em uma das opções exibidas no bottom sheet.
   * @param item Item selecionado.
   */
  const changeOption = useCallback(
    (item: DropdownOption, isSelected?: boolean) => {
      if (isMultiselect) {
        const newArray = selected ? [...(selected as DropdownOption[])] : [];
        if (isSelected) {
          newArray.push(item);
        } else {
          newArray.splice(
            newArray.findIndex((option) => option.value === item.value),
            1
          );
        }

        setSelected(newArray);
        onChange(newArray.map((item) => item.value) as string[] | number[]);
      } else {
        setSelected(item);
        onChange(item.value);
        hideOptions();
      }
    },
    [selected, isMultiselect]
  );

  /**
   * Verifica se determinada option foi selecionada pelo usuario.
   * @param itemValue value da option.
   */
  const isSelected = useCallback(
    (itemValue: string | number) => {
      let result;
      if (isMultiselect && !!selected) {
        result = (selected as DropdownOption[])?.find(
          (item) => item.value === itemValue
        );
      }
      return !!result;
    },
    [selected, isMultiselect]
  );

  /**
   * Retorna valor a ser preenchido no input.
   */
  const getInputValue = useCallback(() => {
    let text = placeholder;
    if (selected) {
      if (isMultiselect) {
        if ((selected as DropdownOption[]).length > 1) {
          text = (selected as DropdownOption[])
            .map((item) => item.label)
            .join(', ');
        } else if ((selected as DropdownOption[]).length !== 0) {
          text = (selected as DropdownOption[])[0].label;
        }
      } else {
        text = (selected as DropdownOption).label;
      }
    }
    return text;
  }, [selected, isMultiselect, placeholder]);

  /**
   * Filtro default para o input search.
   * @param search Dado informado no input.
   * @param item Opção do dropdown para realizar a comparação.
   */
  const onFilterDefault = (search: string, item: DropdownOption) => {
    return RemoveAccents(item.label).includes(search);
  };

  /**
   * Evento disparado ao digitar informações no search para filtrar.
   * @param event KeyboardEvent disparado.
   */
  const keyUpSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;

    const search = RemoveAccents(value);
    const filter = onFilter || onFilterDefault;

    setEmptySearch(!value);

    clearTimeout(debounce);

    debounce = setTimeout(() => {
      setFilteredData(
        options.filter((item, index) => filter(search, item, index))
      );
    }, 300);
  };

  /**
   * Renderização da lista de opções para o bottom sheet.
   */
  const renderOptions = filteredData.map((item, index) => (
    <Option
      key={`dropdown-${index}`}
      role='option'
      truncateOption={truncateOption}
      onClick={() => {
        if (!isMultiselect) changeOption(item);
      }}
    >
      <p>{item.label}</p>

      {isMultiselect ? (
        <InterUICheckBox
          checked={isSelected(item.value)}
          variant='simple'
          onChange={(e) =>
            changeOption(item, (e.target as HTMLInputElement).checked)
          }
        />
      ) : (
        <InterUIIcon
          name='arrow-chevron-right'
          size='md'
          color={theme.colors.primary.A500}
        />
      )}
    </Option>
  ));

  useEffect(() => {
    setSelected(undefined);
    setFilteredData(options);

    setValue();
  }, [options]);

  useEffect(() => {
    setValue();
  }, [value]);

  useEffect(() => {
    if (focusSearch && toggle && searchRef.current) {
      searchRef.current.focus();
    }
  }, [toggle, focusSearch]);

  return (
    <Container margin={margin} {...props}>
      <Label
        id={name}
        role='label'
        disabled={disabled}
        useHelperButton={!!helperButton}
      >
        {label}

        {helperButton && (
          <Button type='button' onClick={onClickHelper}>
            {helperButton}
          </Button>
        )}
      </Label>

      <DropdownFake
        onClick={showOptions}
        disabled={disabled}
        placeholder={!selected ? placeholder : undefined}
        role='listbox'
        aria-labelledby={name}
        aria-disabled={disabled}
        aria-haspopup='true'
        aria-expanded={toggle}
      >
        <p>{getInputValue()}</p>

        {loading ? (
          <Loading>
            <InterUILoading />
          </Loading>
        ) : (
          <InterUIIcon
            name='arrow-chevron-down'
            size='md'
            color={
              disabled
                ? theme.colors.neutral.grayscale.A200
                : theme.colors.primary.A500
            }
          />
        )}
      </DropdownFake>

      <InterUIBottomSheet
        title={labelOptions}
        toggle={toggle}
        onHide={hideOptions}
        link={showCloseLink ? 'Fechar' : undefined}
        onClickedLink={hideOptions}
        aria-labelledby={name}
      >
        {!hideSearch && (
          <InterUIInputGroup className='group-search' margin='0 0 20px'>
            {emptySearch && (
              <InterUIIcon
                name='search'
                size='sm'
                color={theme.colors.primary.A500}
              />
            )}

            <input
              ref={searchRef}
              data-testid='search'
              type='search'
              name='dropdown-search'
              placeholder='Pesquisar'
              autoComplete='off'
              onKeyUp={keyUpSearch}
              aria-labelledby={name}
            />

            {!emptySearch && (
              <InterUIIcon
                data-testid='deleteSearchValue'
                onClick={deleteSearchValue}
                name='contextual-error'
                size='16px'
                color={theme.colors.primary.A500}
              />
            )}
          </InterUIInputGroup>
        )}

        <ContentOptions contentHeight={contentHeight} hideSearch={hideSearch}>
          {renderOptions}
        </ContentOptions>

        {isMultiselect && (
          <InterUIButton onClick={hideOptions}>{buttonLabel}</InterUIButton>
        )}
      </InterUIBottomSheet>
    </Container>
  );
};
