import React from 'react'

export const IconBrazil =
  <div>
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_7513_1188)'>
        <path d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z' fill='#6DA544' />
        <path d='M12 4.69531L21.913 11.9997L12 19.304L2.08698 11.9997L12 4.69531Z' fill='#FFDA44' />
        <path d='M12 16.174C14.3052 16.174 16.1739 14.3052 16.1739 12.0001C16.1739 9.69489 14.3052 7.82617 12 7.82617C9.69483 7.82617 7.82611 9.69489 7.82611 12.0001C7.82611 14.3052 9.69483 16.174 12 16.174Z' fill='#F0F0F0' />
        <path d='M9.91303 11.7383C9.18722 11.7383 8.48677 11.8487 7.82742 12.0535C7.85663 14.3335 9.71306 16.1731 12 16.1731C13.4141 16.1731 14.6631 15.4692 15.4181 14.3933C14.1263 12.7763 12.1388 11.7383 9.91303 11.7383Z' fill='#0052B4' />
        <path d='M16.0966 12.7997C16.1468 12.5408 16.1739 12.2737 16.1739 12.0001C16.1739 9.69484 14.3052 7.82617 12.0001 7.82617C10.28 7.82617 8.80346 8.86684 8.16446 10.3527C8.7293 10.2356 9.31412 10.174 9.91313 10.174C12.3374 10.174 14.5305 11.1817 16.0966 12.7997Z' fill='#0052B4' />
      </g>
      <defs>
        <clipPath id='clip0_7513_1188'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </div>
