import styled, { css } from 'styled-components';

/**
 * Div principal.
 */
export const Container = styled.div<{
  margin?: string;
  type?: string;
  color?: string;
}>`
  display: flex;
  padding: 16px 12px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.neutral.grayscale.A100};

  ${(props) => props.type === 'error' && Error};
  ${(props) => props.type === 'attention' && Attention};
  ${(props) => props.type === 'security' && Security};
  ${(props) => props.color && `background-color: ${props.color};`};

  ${(props) => props.margin && `margin: ${props.margin};`}
`;

/**
 * Ícone a ser exibido.
 */
export const Icon = styled.div`
  margin-right: 13px;
`;

/**
 * Conteúdo central.
 */
export const Content = styled.div`
  width: 100%;
`;

/**
 * Variação da cor do alert.
 */
const Error = css`
  background-color: ${(props) => props.theme.colors.feedback.error.A100};
`;

const Attention = css`
  background-color: ${(props) => props.theme.colors.feedback.warning.A100};
`;

const Security = css`
  background-color: ${(props) => props.theme.colors.feedback.success.A100};
`;
