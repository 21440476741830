/* eslint-disable max-len */
import React from 'react'
import { withTranslation } from 'react-i18next'

import useDataLayer from '../../hooks/useDataLayer/dataLayerFooter'
import Link from './components/Link'
import OrangeIcon from './components/OrangeDS'
import Pagelanguages from './components/Pagelanguages/_index'
import SocialNetwork from './components/SocialNetwork'
import Store from './components/Store'
import { LinkSignLanguage } from './style'

import { IFooterLanguageProps } from './Footer.interface'
import PrivacyCertification from './components/PrivacyCertification/PrivacyCertification'

const FooterEN: React.FC<IFooterLanguageProps> = ({ t, theme, isPj }: IFooterLanguageProps): React.ReactElement => {
  const [ sendDatalayerFooterEvent ] = useDataLayer()

  return (
    <div id='footer'>
      <div className='row'>
        <div className='col-12 col-md-4 col-lg-3 pr-lg-0 d-none d-lg-block'>
          <div className='col-12 pl-lg-0 pr-lg-0 pb-4'>
            <PrivacyCertification t={t} />
          </div>
          <div className='col-12 pl-lg-0'>
            <Store
              sendDatalayerFooterEvent={sendDatalayerFooterEvent}
              t={t}
              isPj={isPj}
            />
          </div>
          <div className='col-12 pl-lg-0 pt-lg-3 mt-lg-4'>
            <span className='title-font fw-footer-600 mb-4 mb-lg-3 fs-footer-16'>{t('social_network.follow_inter')}</span>
            <SocialNetwork
              title={t('social_network.follow_inter')}
              sendDatalayerFooterEvent={sendDatalayerFooterEvent}
              usedTheme={theme}
            />
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-3'>
          <span className='title-font fw-footer-700 mb-3 fs-footer-16'>{t('help_center.products.title')}</span>
          <ul className='list-unstyled'>
            <li>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: t('help_center.products.title'),
                    element_action: 'click button',
                    element_name: t('help_center.products.digital_account'),
                    redirect_url: `${window.location.origin}/en/digital-account/`,
                    c_page: window.location.href,
                  })
                }}
                to='/en/digital-account/'
                title={t('help_center.products.digital_account')}
              >
                {t('help_center.products.digital_account')}
              </Link>
            </li>
          </ul>
        </div>
        <div className='col-12 col-md-4 col-lg-3 mb-3'>
          <span className='title-font fw-footer-700 mb-3 fs-footer-16'>{t('quick_access.title')}</span>
          <ul className='list-unstyled'>
            <li>
              <a
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: t('quick_access.title'),
                    element_action: 'click button',
                    element_name: t('quick_access.investor_relations'),
                    redirect_url: 'https://ri.bancointer.com.br/en/',
                    c_page: window.location.href,
                  })
                }}
                href='https://ri.bancointer.com.br/en/'
                target='_blank'
                rel='noopener noreferrer'
                title={t('quick_access.investor_relations') + ''}
              >
                {t('quick_access.investor_relations')}
              </a>
            </li>
          </ul>
        </div>
        <div className='col-12 col-md-4 col-lg-3 footer-phones mb-3'>
          <span className='title-font fw-footer-700 mb-3 fs-footer-16'>{t('help_center.contact')}</span>
          <div className='d-flex d-md-block'>
            <ul className='list-unstyled list-phones col-6 col-md-12 pl-0 pr-4 pr-md-3'>
              <li>
                <span className='fs-footer-10 fs-footer-md-12'>
                  {t('help_center.capital_cities')}
                </span>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.contact'),
                      element_action: 'click button',
                      element_name: `${t('help_center.capital_cities')} - +55 3003 4070`,
                      redirect_url: 'tel:+5530034070',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:+5530034070'
                >+55 3003 4070
                </a>
              </li>
              <li>
                <span className='fs-footer-10 fs-footer-md-12'>
                  {t('help_center.other_cities')}
                </span>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.contact'),
                      element_action: 'click button',
                      element_name: `${t('help_center.other_cities')} - +55 0800 940 0007`,
                      redirect_url: 'tel:+5508009400007',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:+5508009400007'
                >+55 0800 940 0007
                </a>
              </li>
              <li>
                <span className='fs-footer-10 fs-footer-md-12'>
                  {t('help_center.sac')}
                </span>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.contact'),
                      element_action: 'click button',
                      element_name: `${t('help_center.sac')} - +55 0800 940 9999`,
                      redirect_url: 'tel:+5508009409999',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:+5508009409999'
                >+55 0800 940 9999
                </a>
              </li>
            </ul>
            <ul className='list-unstyled list-phones col-6 col-md-12 pl-0'>
              <li>
                <span className='fs-footer-10 fs-footer-md-12'>
                  {t('help_center.ombudsman')}
                </span>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.contact'),
                      element_action: 'click button',
                      element_name: `${t('help_center.ombudsman')} - +55 0800 940 7772`,
                      redirect_url: 'tel:+5508009407772',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:+5508009407772'
                >+55 0800 940 7772
                </a>
              </li>
              <div className='fs-10 lh-12 d-flex align-items-center fw-600'>
                { theme === 'dark' ? (
                  <div>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M8 9L12 10M12 10L16 9M12 10V13M12 13L9 18M12 13L15 18M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                      <path d='M12 7C11.8674 7 11.7402 6.94732 11.6464 6.85355C11.5527 6.75979 11.5 6.63261 11.5 6.5C11.5 6.36739 11.5527 6.24021 11.6464 6.14645C11.7402 6.05268 11.8674 6 12 6C12.1326 6 12.2598 6.05268 12.3536 6.14645C12.4473 6.24021 12.5 6.36739 12.5 6.5C12.5 6.63261 12.4473 6.75979 12.3536 6.85355C12.2598 6.94732 12.1326 7 12 7Z' fill='black' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                    </svg>

                  </div>
                ) : (
                  <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M7 8L11 9M11 9L15 8M11 9V12M11 12L8 17M11 12L14 17M11 21C16.523 21 21 16.523 21 11C21 5.477 16.523 1 11 1C5.477 1 1 5.477 1 11C1 16.523 5.477 21 11 21Z' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                    <path d='M11 6C10.8674 6 10.7402 5.94732 10.6464 5.85355C10.5527 5.75979 10.5 5.63261 10.5 5.5C10.5 5.36739 10.5527 5.24021 10.6464 5.14645C10.7402 5.05268 10.8674 5 11 5C11.1326 5 11.2598 5.05268 11.3536 5.14645C11.4473 5.24021 11.5 5.36739 11.5 5.5C11.5 5.63261 11.4473 5.75979 11.3536 5.85355C11.2598 5.94732 11.1326 6 11 6Z' fill='white' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                  </svg>

                ) }
                <LinkSignLanguage
                  theme={theme}
                  href='https://inter.co/atendimento-em-libras/'
                  className='ml-1'
                  title={t('service_in_sign_language')}
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: 'Nossos números',
                      element_action: 'click button',
                      element_name: t('service_in_sign_language'),
                      redirect_url: 'https://inter.co/atendimento-em-libras/',
                      c_page: window.location.href,
                    })
                  }}
                > {t('service_in_sign_language')}
                </LinkSignLanguage>
              </div>
            </ul>
          </div>
        </div>
        <div className='col-12 col-md-6 d-lg-none pt-2'>
          <PrivacyCertification t={t} />
        </div>
        <div className='col-12 col-md-6 d-lg-none'>
          <Store
            sendDatalayerFooterEvent={sendDatalayerFooterEvent}
            t={t}
          />
        </div>
        <div className='col-12 col-md-6 d-lg-none mt-md-3'>
          <span className='title-font fw-footer-600 mb-4 mb-lg-3 fs-footer-16'>{t('social_network.follow_inter')}</span>
          <SocialNetwork
            title={t('social_network.follow_inter')}
            sendDatalayerFooterEvent={sendDatalayerFooterEvent}
            usedTheme={theme}
          />
        </div>
      </div>
      <div className='col-12 d-flex mt-3 mb-2 px-0'>
        <Pagelanguages
          t={t}
          theme={theme}
          slug={window.location.pathname}
          pagePT=''
          pageEN=''
          isHeaderUS=''
          ptURL=''
          enURL=''
          lang='en'
        />
      </div>
      <div className='row sub-footer'>
        <div className='col-12 px-3 text-left'>
          <ul className='d-flex list-inline'>
            <li className='text-left fs-footer-10 pr-3'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('privacy_policy'),
                    redirect_url: `${window.location.origin}/en/privacy-policy/`,
                    c_page: window.location.href,
                  })
                }}
                to='/en/privacy-policy/' title={t('privacy_policy')}
              >{t('privacy_policy')}
              </Link>
            </li>
            <li className='text-left fs-footer-10 pr-3'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('data_privacy'),
                    redirect_url: `${window.location.origin}/en/data-privacy/`,
                    c_page: window.location.href,
                  })
                }}
                to='/en/data-privacy/' title={t('data_privacy')}
              >{t('data_privacy')}
              </Link>
            </li>
            <li className='text-left fs-footer-10 pr-3'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('security'),
                    redirect_url: `${window.location.origin}/en/security/`,
                    c_page: window.location.href,
                  })
                }}
                to='/en/security/' title={t('security')}
              >{t('security')}
              </Link>
            </li>
            <li className='text-left fs-footer-10 pr-3'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('security_policy'),
                    redirect_url: `${window.location.origin}/en/cybersecurity-policy/`,
                    c_page: window.location.href,
                  })
                }}
                to='/en/cybersecurity-policy/' title={t('security_policy')}
              >{t('security_policy')}
              </Link>
            </li>
            <li className='text-left fs-footer-10 pr-3'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('security_policy_for_suppliers'),
                    redirect_url: `${window.location.origin}/en/security-policy-for-suppliers/`,
                    c_page: window.location.href,
                  })
                }}
                to='/en/security-policy-for-suppliers/' title={t('security_policy_for_suppliers')}
              >{t('security_policy_for_suppliers')}
              </Link>
            </li>
          </ul>
          <address>
            <div className='d-flex align-items-center mb-3'>
              &copy; <span className='fs-footer-10 ml-1 text-left' dangerouslySetInnerHTML={{ __html: t('cnpj') }} />
            </div>
            <div className='d-xl-flex'>
              <div className='d-flex mr-xl-3 mb-2 mb-xl-0'>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: '',
                      element_action: 'click button',
                      element_name: t('address_bh'),
                      redirect_url: 'https://goo.gl/maps/6ELbHe2AsaN6VzsE7',
                      c_page: window.location.href,
                    })
                  }}
                  href='https://goo.gl/maps/6ELbHe2AsaN6VzsE7' className='address d-flex' target='_blank' rel='noopener noreferrer'
                >
                  <OrangeIcon size='SM' icon='location' />
                  <span className='col fs-footer-10 pl-1 text-left' dangerouslySetInnerHTML={{ __html: t('address_bh') }} />
                </a>
              </div>
              <div className='d-flex mr-xl-3 mb-2 mb-xl-0'>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: '',
                      element_action: 'click button',
                      element_name: t('address_sp'),
                      redirect_url: 'https://goo.gl/maps/dhFZGQQuaAsU8fuy9',
                      c_page: window.location.href,
                    })
                  }}
                  href='https://goo.gl/maps/dhFZGQQuaAsU8fuy9' className='address d-flex' target='_blank' rel='noopener noreferrer'
                >
                  <OrangeIcon size='SM' icon='location' />
                  <span className='col fs-footer-10 pl-1 text-left' dangerouslySetInnerHTML={{ __html: t('address_sp') }} />
                </a>
              </div>
            </div>
          </address>
        </div>
      </div>
    </div>
  )
}

export default withTranslation('Footer')(FooterEN)
