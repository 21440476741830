import React from 'react';

import { OverlayBlock } from './InterUIOverlay.styles';
import { IInterUIOverlayProps } from '../../interfaces/inter-ui-overlay-props';

/**
 * Componente Inter UI Overlay.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUIOverlay: React.FC<
  IInterUIOverlayProps & React.HTMLAttributes<HTMLDivElement>
> = ({ show, background, ...props }) => {
  return (
    <OverlayBlock
      show={show}
      background={background}
      role='overlay'
      {...props}
    />
  );
};
