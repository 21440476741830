import { css } from 'styled-components';

/**
 * Ícones disponíveis.
 */
export const InterIconItems = css`
  .inter-ic-agenda:before {
    content: '\\e900';
  }
  .inter-ic-arrow-chevron-down:before {
    content: '\\e901';
  }
  .inter-ic-arrow-chevron-left:before {
    content: '\\e902';
  }
  .inter-ic-arrow-chevron-right:before {
    content: '\\e903';
  }
  .inter-ic-arrow-chevron-up:before {
    content: '\\e904';
  }
  .inter-ic-arrow-down:before {
    content: '\\e905';
  }
  .inter-ic-arrow-left:before {
    content: '\\e906';
  }
  .inter-ic-arrow-right:before {
    content: '\\e907';
  }
  .inter-ic-arrow-up:before {
    content: '\\e908';
  }
  .inter-ic-attachment:before {
    content: '\\e909';
  }
  .inter-ic-attention:before {
    content: '\\e90a';
  }
  .inter-ic-bed:before {
    content: '\\e90b';
  }
  .inter-ic-book-open:before {
    content: '\\e90c';
  }
  .inter-ic-camera:before {
    content: '\\e90d';
  }
  .inter-ic-canceled:before {
    content: '\\e90e';
  }
  .inter-ic-card:before {
    content: '\\e90f';
  }
  .inter-ic-cashback:before {
    content: '\\e910';
  }
  .inter-ic-check-circle:before {
    content: '\\e911';
  }
  .inter-ic-check-underlined:before {
    content: '\\e912';
  }
  .inter-ic-check:before {
    content: '\\e913';
  }
  .inter-ic-contextual-attention:before {
    content: '\\e914';
  }
  .inter-ic-contextual-error:before {
    content: '\\e915';
  }
  .inter-ic-contextual-info:before {
    content: '\\e916';
  }
  .inter-ic-contextual-success:before {
    content: '\\e917';
  }
  .inter-ic-credit:before {
    content: '\\e918';
  }
  .inter-ic-dollar:before {
    content: '\\e919';
  }
  .inter-ic-download:before {
    content: '\\e91a';
  }
  .inter-ic-exit:before {
    content: '\\e91b';
  }
  .inter-ic-goal:before {
    content: '\\e91c';
  }
  .inter-ic-health:before {
    content: '\\e91d';
  }
  .inter-ic-heart:before {
    content: '\\e91e';
  }
  .inter-ic-help:before {
    content: '\\e91f';
  }
  .inter-ic-hide:before {
    content: '\\e920';
  }
  .inter-ic-home:before {
    content: '\\e921';
  }
  .inter-ic-image:before {
    content: '\\e922';
  }
  .inter-ic-images:before {
    content: '\\e923';
  }
  .inter-ic-info:before {
    content: '\\e924';
  }
  .inter-ic-investment:before {
    content: '\\e925';
  }
  .inter-ic-list-check:before {
    content: '\\e926';
  }
  .inter-ic-location:before {
    content: '\\e927';
  }
  .inter-ic-nearby-terminals:before {
    content: '\\e928';
  }
  .inter-ic-no-taxes:before {
    content: '\\e929';
  }
  .inter-ic-orange:before {
    content: '\\e92a';
  }
  .inter-ic-pause:before {
    content: '\\e92b';
  }
  .inter-ic-pdf-file:before {
    content: '\\e92c';
  }
  .inter-ic-pending:before {
    content: '\\e92d';
  }
  .inter-ic-pig-increased:before {
    content: '\\e92e';
  }
  .inter-ic-pill:before {
    content: '\\e92f';
  }
  .inter-ic-play-outline:before {
    content: '\\e930';
  }
  .inter-ic-play:before {
    content: '\\e931';
  }
  .inter-ic-refresh:before {
    content: '\\e932';
  }
  .inter-ic-safe-locked:before {
    content: '\\e933';
  }
  .inter-ic-search:before {
    content: '\\e934';
  }
  .inter-ic-security:before {
    content: '\\e935';
  }
  .inter-ic-send-image:before {
    content: '\\e936';
  }
  .inter-ic-show:before {
    content: '\\e937';
  }
  .inter-ic-smartphone:before {
    content: '\\e938';
  }
  .inter-ic-spending:before {
    content: '\\e939';
  }
  .inter-ic-terminals:before {
    content: '\\e93a';
  }
  .inter-ic-trash:before {
    content: '\\e93b';
  }
  .inter-ic-user-account:before {
    content: '\\e93c';
  }
  .inter-ic-wallet:before {
    content: '\\e93d';
  }
  .inter-ic-whatsapp:before {
    content: '\\e93e';
  }
`;
