import { useEffect } from 'react'

import { Theme } from '@/common/types/theme'

type UseThemeChange = {
  onThemeChange: (theme: Theme) => void
}

export const useThemeChange = ({ onThemeChange }: UseThemeChange): void => {
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'theme') {
          onThemeChange(document.documentElement.getAttribute('theme') as Theme)
        }
      })
    })

    observer.observe(document.documentElement, { attributes: true })
  }, [onThemeChange])
}
