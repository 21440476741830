import React from 'react';

import { IInterUIIconProps } from '../../interfaces/inter-ui-icon-props';
import { Container } from './InterUIIcon.styles';

/**
 * Componente Inter UI Ícone.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUIIcon: React.FC<
  IInterUIIconProps & React.HtmlHTMLAttributes<HTMLSpanElement>
> = ({ className, ...props }) => {
  return (
    <Container
      data-testid='inter-ui-icon'
      className={`inter-ic-${props.name} ${className}`}
      {...props}
    />
  );
};
