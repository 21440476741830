import { InterWebviewBridge } from 'core';
import { WbWalletActions } from 'enums';
const InterWbWallet = () => {
    const iWb = InterWebviewBridge.getInstance();
    return {
        /**
         * Método para a escolha de forma de pagamento.
         * @summary additionalFields e additionalQueryRequestParams deve sempre ser um objeto.
         * @param walletClientId indentificador unico do produto junto ao wallet.
         * @param amount valor em centavos.
         * @param additionalFields campo contendo informações adicionais, chave|valor
         * @param additionalQueryRequestParams campo contendo informações adicionais, chave|valor
         * @returns informações referentes ao método de pagamento selecionado.
         */
        openWallet(walletClientId, amount, additionalFields, additionalQueryRequestParams) {
            const payload = {
                walletClientId,
                amount,
                additionalFields,
                additionalQueryRequestParams,
            };
            return iWb.execute({
                action: WbWalletActions.OPEN_WALLET,
                metadata: payload,
            });
        },
        /**
         * Método responsável por finalizar a contratação via bridge.
         * @param openWalletResult dados do retorno do método open wallet.
         * @param transactionType indetificador do tipo de transação do produto.
         * @returns dados referentes a transação junto ao wallet.
         */
        contractWallet(openWalletResult, transactionType) {
            const payload = {
                transactionType,
                walletLibraryPaymentMethodResult: openWalletResult,
            };
            return iWb.execute({
                action: WbWalletActions.CONTRACT_WALLET,
                metadata: payload,
            });
        },
    };
};
export const interWbWallet = InterWbWallet();
