/* eslint-disable max-len */
import React from 'react'
import { withTranslation } from 'react-i18next'

// DataLayer
import useDataLayer from '../../hooks/useDataLayer/dataLayerFooter'
import Link from './components/Link'
import OrangeIcon from './components/OrangeDS'
import Pagelanguages from './components/Pagelanguages/_index'
import PrivacyCertification from './components/PrivacyCertification/PrivacyCertification'
import SocialNetwork from './components/SocialNetwork'
import Store from './components/Store'
import { LinkSignLanguage } from './style'

import { IFooterLanguageProps } from './Footer.interface'

const FooterPT: React.FC<IFooterLanguageProps> = ({ t, theme, isPj }: IFooterLanguageProps): React.ReactElement => {
  const [ sendDatalayerFooterEvent ] = useDataLayer()

  return (
    <div id='footer'>
      <div className='row'>
        <div className='col-12 col-md-4 col-lg-3 d-none d-lg-block pr-lg-0 social-app'>
          <div className='col-12 pl-lg-0 pr-lg-0 pb-4'>
            <PrivacyCertification t={t} />
          </div>
          <div className='col-12 pl-lg-0'>
            <Store
              sendDatalayerFooterEvent={sendDatalayerFooterEvent}
              isPj={isPj}
              t={t}
            />
          </div>
          <div className='col-12 pl-lg-0 pt-lg-3 mt-lg-4'>
            <span className='title-font fw-footer-600 mb-4 mb-lg-3 fs-footer-16'>{t('social_network.follow_inter')}</span>
            <SocialNetwork
              title={t('social_network.follow_inter')}
              usedTheme={theme}
              sendDatalayerFooterEvent={sendDatalayerFooterEvent}
            />
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-3'>
          <span className='title-font fw-footer-700 mb-3 fs-footer-16'>{t('aboutUs.title')}</span>
          <div className='d-flex d-md-block'>
            <ul className='list-unstyled col-6 col-md-12 pl-0 pr-5 pr-md-3'>
              <li data-footer='conta-digital-pessoa-fisica'>
                <Link
                  to='/o-inter/'
                  title={t('aboutUs.inter')}
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('aboutUs.title'),
                      element_action: 'click button',
                      element_name: t('aboutUs.inter'),
                      redirect_url: `${window.location.origin}/o-inter/`,
                      c_page: window.location.href,
                    })
                  }}
                >
                  {t('aboutUs.inter')}
                </Link>
              </li>
              <li data-footer='portal-ri'>
                <a
                  href='https://investors.inter.co/'
                  title={t('aboutUs.portalRI')}
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('aboutUs.portalRI'),
                      element_action: 'click button',
                      element_name: t('aboutUs.portalRI'),
                      redirect_url: 'https://investors.inter.co/',
                      c_page: window.location.href,
                    })
                  }}
                >
                  {t('aboutUs.portalRI')}
                </a>
              </li>
              <li data-footer='etica-e-compliance'>
                <Link
                  to='/etica-e-compliance/'
                  title={t('aboutUs.ethic')}
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('aboutUs.ethic'),
                      element_action: 'click button',
                      element_name: t('aboutUs.ethic'),
                      redirect_url: '/etica-e-compliance/',
                      c_page: window.location.href,
                    })
                  }}
                >
                  {t('aboutUs.ethic')}
                </Link>
              </li>
              <li data-footer='inter-invest'>
                <Link
                  to='/pra-voce/investimentos/'
                  title={t('aboutUs.interInvest')}
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('aboutUs.interInvest'),
                      element_action: 'click button',
                      element_name: t('aboutUs.interInvest'),
                      redirect_url: `${window.location.origin}/pra-voce/investimentos/`,
                      c_page: window.location.href,
                    })
                  }}
                >
                  {t('aboutUs.interInvest')}
                </Link>
              </li>
              <li data-footer='blog'>
                <a
                  href='https://blog.inter.co/'
                  title={t('aboutUs.blog')}
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('aboutUs.blog'),
                      element_action: 'click button',
                      element_name: t('aboutUs.blog'),
                      redirect_url: 'https://blog.inter.co/',
                      c_page: window.location.href,
                    })
                  }}
                >
                  {t('aboutUs.blog')}
                </a>
              </li>
              <li data-footer='newsroom'>
                <Link
                  to='/newsroom/'
                  title={t('aboutUs.newsroom')}
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('aboutUs.newsroom'),
                      element_action: 'click button',
                      element_name: t('aboutUs.newsroom'),
                      redirect_url: `${window.location.origin}/newsroom/`,
                      c_page: window.location.href,
                    })
                  }}
                >
                  Newsroom
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-3'>
          <span className='title-font fw-footer-700 mb-3 fs-footer-16'>{t('quick_access.title')}</span>
          <div className='d-flex d-md-block'>
            <ul className='list-unstyled col-6 col-md-12 pl-0 pr-4 pr-md-3'>
              <li data-footer='central-de-ajuda'>
                <a
                  href='https://ajuda.inter.co/'
                  target='_blank'
                  rel='noopener noreferrer'
                  title={t('help_center.title') + ''}
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('quick_access.title'),
                      element_action: 'click button',
                      element_name: t('help_center.title'),
                      redirect_url: 'https://ajuda.inter.co/',
                      c_page: window.location.href,
                    })
                  }}
                >
                  {t('help_center.title')}
                </a>
              </li>
              <li data-footer='canais-de-atendimento'>
                <Link
                  to='/canais-de-atendimento/'
                  title={t('help_center.call_center')}
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('quick_access.title'),
                      element_action: 'click button',
                      element_name: t('help_center.call_center'),
                      redirect_url: `${window.location.origin}/canais-de-atendimento/`,
                      c_page: window.location.href,
                    })
                  }}
                >{t('help_center.call_center')}
                </Link>
              </li>
              <li data-footer='ouvidoria'>
                <Link
                  to='/ouvidoria/'
                  title={t('help_center.ombudsman')}
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('quick_access.title'),
                      element_action: 'click button',
                      element_name: t('help_center.ombudsman'),
                      redirect_url: `${window.location.origin}/ouvidoria/`,
                      c_page: window.location.href,
                    })
                  }}
                >{t('help_center.ombudsman')}
                </Link>
              </li>
            </ul>
            <ul className='list-unstyled col-6 col-md-12 pl-0'>
              <li data-footer='segunda-via-do-boleto'>
                <Link
                  to='/segunda-via-boleto/'
                  title={t('quick_access.second_billet')}
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('quick_access.title'),
                      element_action: 'click button',
                      element_name: t('quick_access.second_billet'),
                      redirect_url: `${window.location.origin}/segunda-via-boleto/`,
                      c_page: window.location.href,
                    })
                  }}
                >
                  {t('quick_access.second_billet')}
                </Link>
              </li>
              <li data-footer='saldo-devedor'>
                <Link
                  to='/saldo-devedor/'
                  title={t('quick_access.balance')}
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('quick_access.title'),
                      element_action: 'click button',
                      element_name: t('quick_access.balance'),
                      redirect_url: `${window.location.origin}/saldo-devedor/`,
                      c_page: window.location.href,
                    })
                  }}
                >{t('quick_access.balance')}
                </Link>
              </li>
              <li data-footer='negocie-sua-divida'>
                <Link
                  to='/negocie/'
                  title={t('quick_access.negotiate')}
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('quick_access.title'),
                      element_action: 'click button',
                      element_name: t('quick_access.negotiate'),
                      redirect_url: `${window.location.origin}/negocie/`,
                      c_page: window.location.href,
                    })
                  }}
                >{t('quick_access.negotiate')}
                </Link>
              </li>
              <li data-footer='leilao-de-carteiras-de-credito'>
                <Link
                  to='/leilao-de-carteiras-de-credito/'
                  title={t('quick_access.auctions')}
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('quick_access.title'),
                      element_action: 'click button',
                      element_name: t('quick_access.auctions'),
                      redirect_url: `${window.location.origin}/leilao-de-carteiras-de-credito/`,
                      c_page: window.location.href,
                    })
                  }}
                >{t('quick_access.auctions')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-3 footer-phones'>
          <span className='title-font fw-footer-700 mb-3 fs-footer-16'>{t('help_center.contact')}</span>
          <div className='d-flex d-md-block'>
            <ul className='list-unstyled list-phones col-6 col-md-12 pl-0 pr-4 pr-md-3 mb-2'>
              <li>
                <span className='fs-footer-10 fs-footer-md-12'>
                  {t('help_center.capital_cities')}
                </span>
                <a
                  href='tel:30034070'
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.contact'),
                      element_action: 'click button',
                      element_name: `${t('help_center.capital_cities')} - +55 3003 4070`,
                      redirect_url: 'tel:30034070',
                      c_page: window.location.href,
                    })
                  }}
                >3003 4070
                </a>
              </li>
              <li>
                <span className='fs-footer-10 fs-footer-md-12'>
                  {t('help_center.other_cities')}
                </span>
                <a
                  href='tel:08009400007'
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.contact'),
                      element_action: 'click button',
                      element_name: `${t('help_center.other_cities')} - +55 0800 940 0007`,
                      redirect_url: 'tel:08009400007',
                      c_page: window.location.href,
                    })
                  }}
                >0800 940 0007
                </a>
              </li>
              <li>
                <span className='fs-footer-10 fs-footer-md-12'>
                  {t('help_center.sac')}
                </span>
                <a
                  href='tel:08009409999'
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.contact'),
                      element_action: 'click button',
                      element_name: `${t('help_center.sac')} - +55 0800 940 9999`,
                      redirect_url: 'tel:08009409999',
                      c_page: window.location.href,
                    })
                  }}
                >0800 940 9999
                </a>
              </li>
            </ul>
            <ul className='list-unstyled list-phones col-6 col-md-12 pl-0'>
              <li>
                <span className='fs-footer-10 fs-footer-md-12'>
                  {t('help_center.ombudsman')}
                </span>
                <a
                  href='tel:08009407772'
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.contact'),
                      element_action: 'click button',
                      element_name: `${t('help_center.ombudsman')} - +55 0800 940 7772`,
                      redirect_url: 'tel:08009407772',
                      c_page: window.location.href,
                    })
                  }}
                >0800 940 7772
                </a>
              </li>
              <li
                className='fs-footer-12 d-none d-md-block disclamer-md'
                dangerouslySetInnerHTML={{ __html: t('disclamer') }}
              />
              <div className='fs-10 lh-12 d-flex align-items-center fw-600'>
                <div>
                  { theme === 'dark' ? (
                    <div>
                      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M8 9L12 10M12 10L16 9M12 10V13M12 13L9 18M12 13L15 18M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                        <path d='M12 7C11.8674 7 11.7402 6.94732 11.6464 6.85355C11.5527 6.75979 11.5 6.63261 11.5 6.5C11.5 6.36739 11.5527 6.24021 11.6464 6.14645C11.7402 6.05268 11.8674 6 12 6C12.1326 6 12.2598 6.05268 12.3536 6.14645C12.4473 6.24021 12.5 6.36739 12.5 6.5C12.5 6.63261 12.4473 6.75979 12.3536 6.85355C12.2598 6.94732 12.1326 7 12 7Z' fill='black' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                      </svg>

                    </div>
                  ) : (
                    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M7 8L11 9M11 9L15 8M11 9V12M11 12L8 17M11 12L14 17M11 21C16.523 21 21 16.523 21 11C21 5.477 16.523 1 11 1C5.477 1 1 5.477 1 11C1 16.523 5.477 21 11 21Z' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                      <path d='M11 6C10.8674 6 10.7402 5.94732 10.6464 5.85355C10.5527 5.75979 10.5 5.63261 10.5 5.5C10.5 5.36739 10.5527 5.24021 10.6464 5.14645C10.7402 5.05268 10.8674 5 11 5C11.1326 5 11.2598 5.05268 11.3536 5.14645C11.4473 5.24021 11.5 5.36739 11.5 5.5C11.5 5.63261 11.4473 5.75979 11.3536 5.85355C11.2598 5.94732 11.1326 6 11 6Z' fill='white' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                    </svg>

                  ) }
                </div>
                <LinkSignLanguage
                  theme={theme}
                  href='https://inter.co/atendimento-em-libras/'
                  className='ml-1'
                  title={t('service_in_sign_language')}
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: 'footer',
                      element_action: 'click button',
                      element_name: t('service_in_sign_language'),
                      redirect_url: 'https://inter.co/atendimento-em-libras/',
                      c_page: window.location.href,
                    })
                  }}
                > {t('service_in_sign_language')}
                </LinkSignLanguage>
              </div>
            </ul>
          </div>
        </div>
        <div className='col-12 pb-4 d-md-none disclamer text-left'>
          <p className='fs-footer-12 mb-0' dangerouslySetInnerHTML={{ __html: t('disclamer') }} />
        </div>
        <div className='col-12 col-md-6 d-lg-none pt-2 my-3 mb-md-4 justify-content-end'>
          <PrivacyCertification t={t} />
        </div>
        <div className='col-12 col-md-6 d-lg-none'>
          <Store
            sendDatalayerFooterEvent={sendDatalayerFooterEvent}
            t={t}
          />
        </div>
        <div className='col-12 col-md-6 d-lg-none mt-md-3'>
          <span className='title-font fw-footer-600 mb-4 mb-lg-3 fs-footer-16'>{t('social_network.follow_inter')}</span>
          <SocialNetwork
            title={t('social_network.follow_inter')}
            sendDatalayerFooterEvent={sendDatalayerFooterEvent}
            usedTheme={theme}
          />
        </div>
      </div>
      <div className='col-12 d-flex mt-3 mb-2 px-0'>
        <Pagelanguages
          t={t}
          theme={theme}
          slug={window.location.pathname}
          pagePT=''
          pageEN=''
          isHeaderUS=''
          ptURL='/'
          enURL=''
          lang='pt'
        />
      </div>
      <div className='row sub-footer'>
        <div className='col-12 text-left'>
          <ul className='list-inline'>
            <li className='list-inline-item' data-footer='trabalhe-com-a-gente'>
              <a
                href='https://carreiras.inter.co'
                title='Trabalhe com a gente'
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('work_with_us'),
                    redirect_url: 'https://carreiras.inter.co',
                    c_page: window.location.href,
                  })
                }}
              >
                {t('work_with_us')}
              </a>
            </li>
            <li className='list-inline-item' data-footer='cotacao-dolar'>
              <Link
                to='/pra-voce/cartoes/cotacao-dolar/'
                title={t('quick_access.dollar_quotation')}
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('quick_access.dollar_quotation'),
                    redirect_url: `${window.location.origin}/pra-voce/cartoes/cotacao-dolar/`,
                    c_page: window.location.href,
                  })
                }}
              >
                {t('quick_access.dollar_quotation')}
              </Link>
            </li>
            <li className='list-inline-item' data-footer='tarifas'>
              <Link
                to='/tarifas/'
                title={t('fares')}
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('fares'),
                    redirect_url: `${window.location.origin}/tarifas/`,
                    c_page: window.location.href,
                  })
                }}
              >{t('fares')}
              </Link>
            </li>
            <li className='list-inline-item' data-footer='seguranca'>
              <Link
                to='/seguranca/'
                title={t('security')}
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('security'),
                    redirect_url: `${window.location.origin}/seguranca/`,
                    c_page: window.location.href,
                  })
                }}
              >{t('security')}
              </Link>
            </li>
            <li className='list-inline-item' data-footer='privacidade-de-dados'>
              <Link
                to='/privacidade-de-dados/'
                title={t('data_privacy')}
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('data_privacy'),
                    redirect_url: `${window.location.origin}/privacidade-de-dados/`,
                    c_page: window.location.href,
                  })
                }}
              >{t('data_privacy')}
              </Link>
            </li>
            <li className='list-inline-item' data-footer='politica-de-privacidade'>
              <Link
                to='/politica-de-privacidade/'
                title={t('privacy_policy')}
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('privacy_policy'),
                    redirect_url: `${window.location.origin}/politica-de-privacidade/`,
                    c_page: window.location.href,
                  })
                }}
              >{t('privacy_policy')}
              </Link>
            </li>
            <li className='list-inline-item' data-footer='canal-de-denuncia'>
              <a
                href='https://www.canaldeetica.com.br/bancointer/'
                title={t('help_center.report_channel')}
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('help_center.report_channel'),
                    redirect_url: 'https://www.canaldeetica.com.br/bancointer/',
                    c_page: window.location.href,
                  })
                }}
              >{t('help_center.report_channel')}
              </a>
            </li>
            <li className='list-inline-item' data-footer='politica-de-seguranca-da-informacao'>
              <Link
                to='/politica-de-seguranca/'
                title={t('security_policy')}
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('security_policy'),
                    redirect_url: `${window.location.origin}/politica-de-seguranca/`,
                    c_page: window.location.href,
                  })
                }}
              >{t('security_policy')}
              </Link>
            </li>
            <li className='list-inline-item' data-footer='politica-de-seguranca-da-informacao-para-fornecedores'>
              <Link
                to='/politica-de-seguranca-para-fornecedores/'
                title={t('security_policy_partners')}
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('security_policy_partners'),
                    redirect_url: `${window.location.origin}/politica-de-seguranca-para-fornecedores/`,
                    c_page: window.location.href,
                  })
                }}
              >{t('security_policy_partners')}
              </Link>
            </li>
          </ul>
          <address>
            <div className='d-flex align-items-center mb-3'>
              &copy; <span className='fs-footer-10 ml-1 text-left' dangerouslySetInnerHTML={{ __html: t('cnpj') }} />
            </div>
            <div className='d-xl-flex'>
              <div className='d-flex mr-xl-3 mb-2 mb-xl-0'>
                <a
                  href='https://goo.gl/maps/6ELbHe2AsaN6VzsE7'
                  className='address d-flex'
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: '',
                      element_action: 'click button',
                      element_name: t('address_bh'),
                      redirect_url: 'https://goo.gl/maps/6ELbHe2AsaN6VzsE7',
                      c_page: window.location.href,
                    })
                  }}
                >
                  <OrangeIcon size='SM' icon='location' />
                  <span
                    className='col fs-footer-10 pl-1 text-left'
                    dangerouslySetInnerHTML={{ __html: t('address_bh') }}
                  />
                </a>
              </div>
              <div className='d-flex mr-xl-3 mb-2 mb-xl-0'>
                <a
                  href='https://goo.gl/maps/dhFZGQQuaAsU8fuy9'
                  className='address d-flex'
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: '',
                      element_action: 'click button',
                      element_name: t('address_sp'),
                      redirect_url: 'https://goo.gl/maps/dhFZGQQuaAsU8fuy9',
                      c_page: window.location.href,
                    })
                  }}
                >
                  <OrangeIcon size='SM' icon='location' />
                  <span
                    className='col fs-footer-10 pl-1 text-left'
                    dangerouslySetInnerHTML={{ __html: t('address_sp') }}
                  />
                </a>
              </div>
            </div>
          </address>
        </div>
      </div>
    </div>
  )
}

export default withTranslation('Footer')(FooterPT)
