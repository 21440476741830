import React, { useState } from 'react';

import { IInterUIWizardProps } from '../../interfaces/inter-ui-wizard-props';
import {
  ButtonNext,
  ButtonPrev,
  ButtonSkip,
  Container,
  Description,
  Dot,
  DotsControl,
  Icon,
  Image,
  NavigationContent,
  SlideContent,
  Title
} from './InterUIWizard.styles';

/**
 * Componente Inter UI Wizard.
 * @param props Propriedades disponíveis para renderização do wizard.
 */
export const InterUIWizard: React.FC<IInterUIWizardProps> = ({
  initialStep = 0,
  slides,
  skipCb,
  finishCb,
  hideSkip,
  skipVertical,
  skipHorizontal,
  type = 'default',
  ...props
}) => {
  const [currentSlideIdx, setCurrentSlideIdx] = useState<number>(initialStep);

  const slidersToDisplay = slides.slice(currentSlideIdx, currentSlideIdx + 1);

  const prevSlide = (): void => {
    const resetToVeryBack = currentSlideIdx === 0;

    const index = resetToVeryBack ? slides.length - 1 : currentSlideIdx - 1;

    setCurrentSlideIdx(index);
  };

  const nextSlide = (): void => {
    const resetIndex = currentSlideIdx === slides.length - 1;

    const index = resetIndex ? 0 : currentSlideIdx + 1;

    setCurrentSlideIdx(index);
  };

  const renderSliders = slidersToDisplay.map((slide, index) => {
    return (
      <SlideContent key={index} data-testid='inter-ui-wizard-slide'>
        {slide.icon && <Icon>{slide.icon}</Icon>}
        {slide.image && <Image src={slide.image} alt={slide.title} />}
        <Title>{slide.title}</Title>
        <Description>{slide.description}</Description>
      </SlideContent>
    );
  });

  const renderDots = slides.map((_slide, index) => {
    const activeDot = index === currentSlideIdx;

    return <Dot key={index} className={activeDot ? 'active' : 'disabled'} />;
  });

  let leftBtn;
  let skipBtn;

  if (type === 'default') {
    leftBtn = (
      <ButtonPrev
        data-testid='inter-ui-wizard-btn-left'
        onClick={currentSlideIdx === 0 ? skipCb : prevSlide}
      >
        {currentSlideIdx === 0 ? 'Pular' : 'Anterior'}
      </ButtonPrev>
    );
  }

  if (type === 'moveOn' && !hideSkip) {
    skipBtn = (
      <ButtonSkip
        data-testid='inter-ui-wizard-btn-left'
        skipVertical={skipVertical}
        skipHorizontal={skipHorizontal}
        onClick={skipCb}
      >
        {currentSlideIdx !== slides.length - 1 ? 'Pular' : ''}
      </ButtonSkip>
    );
  }

  return (
    <Container data-testid='inter-ui-wizard' {...props}>
      {renderSliders}

      <NavigationContent data-testid='inter-ui-wizard-navigation'>
        {leftBtn}
        {skipBtn}
        <DotsControl data-testid='inter-ui-wizard-dots' type={type}>
          {renderDots}
        </DotsControl>

        <ButtonNext
          data-testid='inter-ui-wizard-btn-right'
          onClick={currentSlideIdx === slides.length - 1 ? finishCb : nextSlide}
        >
          Próximo
        </ButtonNext>
      </NavigationContent>
    </Container>
  );
};
