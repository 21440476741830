import React from 'react';

import { IInterUISeparatorProps } from '../../interfaces/inter-ui-separator-props';
import { Separator } from './InterUISeparator.styles';

/**
 * Componente Inter UI Separator.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUISeparator: React.FC<
  IInterUISeparatorProps & React.HTMLAttributes<HTMLDivElement>
> = ({ variant, height, margin, ...props }) => {
  return (
    <Separator
      variant={variant}
      height={height}
      margin={margin}
      role='separator'
      {...props}
    />
  );
};
