import React from 'react';

import { IInterUIContainerProps } from '../../interfaces/inter-ui-container-props';
import { Container, Content, StickyFooter } from './InterUIContainer.styles';

type InterUIContainerType = React.FC<
  IInterUIContainerProps & React.HTMLAttributes<HTMLDivElement>
>;

/**
 * Componente Inter UI Container.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUIContainer: InterUIContainerType = ({
  fullPage,
  margin,
  children,
  stickyFooter,
  ...props
}) => {
  return (
    <Container
      data-testid='inter-ui-container'
      fullPage={fullPage}
      stickyFooter={!!stickyFooter}
      {...props}
    >
      <Content data-testid='inter-ui-container-content' margin={margin}>
        {children}
      </Content>

      {stickyFooter && (
        <StickyFooter
          data-testid='inter-ui-container-sticky-footer'
          margin={margin}
        >
          {stickyFooter}
        </StickyFooter>
      )}
    </Container>
  );
};
