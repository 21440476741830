import styled from 'styled-components';
import { AlignItems } from '../../interfaces/inter-ui-commons-props';

/**
 * Div principal.
 */
export const Container = styled.div<{ margin?: string }>`
  display: flex;
  flex: auto;
  ${(props) => props.margin && `margin: ${props.margin};`}
`;

/**
 * Conteúdo central.
 */
export const Content = styled.div<{
  variant?: 'horizontal' | 'vertical';
  align?: AlignItems;
}>`
  display: grid;
  gap: 0px 0px;
  width: 100%;
  align-items: ${(props) => props.align || `center`};

  ${(props) =>
    props.variant === 'horizontal' &&
    `grid-template-columns: auto auto; grid-template-rows: 1fr;`}

  ${(props) =>
    props.variant === 'vertical' &&
    `grid-template-columns: content auto; grid-template-rows: auto auto;`}
`;

/**
 * Container responsável por posicionar o título no centro.
 */
export const Title = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * Ícone exibido na lateral.
 */
export const Icon = styled.span`
  margin-left: 4px;
  text-align: left;
  cursor: pointer;
`;

/**
 * Valor posicionado à direira/esquerda.
 */
export const Value = styled.span<{ variant?: 'horizontal' | 'vertical' }>`
  ${(props) => props.variant === 'horizontal' && `text-align: right`}

  ${(props) => props.variant === 'vertical' && `text-align: left`}
`;
