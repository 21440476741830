import React, { useContext } from 'react'
import GatsbyLink from 'gatsby-link'
import LinkContext from '../../contexts/link'
import { ILink } from '../../Footer.interface'

/**
 * Função que gera um link com conteúdo do Gatsby
 * @param children
 * @param to
 * @param className
 * @param style
 * @param arialLabel
 * @param title
 * @param onClick
 */
const Link: React.FC<ILink> = ({ children, to, className, style, ariaLabel, title, onClick }: ILink): React.ReactElement => {
  const isGatsbySite = useContext(LinkContext)

  return (
    isGatsbySite
      ? (
        <GatsbyLink
          className={className}
          style={style}
          to={to}
          aria-label={ariaLabel}
          title={title + ''}
        >
          {children}
        </GatsbyLink>
      )
      : (
        <a
          className={className}
          style={style}
          href={`${process.env.REACT_APP_SITE_BASE_URL}${to}`}
          aria-label={ariaLabel}
          title={title + ''}
          onClick={onClick}
        >
          {children}
        </a>
      )
  )
}

export default Link
