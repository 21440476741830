import React from 'react';

import { IInterUIListDescriptionProps } from '../../interfaces/inter-ui-list-description-props';
import { Container, Content, Icon } from './InterUIListDescription.styles';

/**
 * Componente Inter UI List Description.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUIListDescription: React.FC<
  IInterUIListDescriptionProps & React.HtmlHTMLAttributes<HTMLElement>
> = ({
  margin,
  alignAllItems,
  AlignOnlyLeftIcon,
  AlignOnlyRightIcon,
  iconLeft,
  iconRight,
  borderBottom,
  children,
  withBorder,
  borderColor,
  small,
  ...props
}) => {
    return (
      <Container
        margin={margin}
        borderBottom={borderBottom}
        alignItems={alignAllItems}
        withBorder={withBorder}
        borderColor={borderColor}
        small={small}
        data-testid='inter-ui-list-desc-container'
        {...props}
      >
        <Icon
          left={!!iconLeft}
          align={AlignOnlyLeftIcon || alignAllItems}
          data-testid='inter-ui-list-desc-left'
        >
          {iconLeft}
        </Icon>

        <Content>{children}</Content>

        <Icon
          right={!!iconRight}
          align={AlignOnlyRightIcon || alignAllItems}
          data-testid='inter-ui-list-desc-right'
        >
          {iconRight}
        </Icon>
      </Container>
    );
  };
