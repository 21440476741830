import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import { IInterUIAccordionProps } from '../../interfaces/inter-ui-accordion-props';
import {
  Collapse,
  Container,
  Header,
  HeaderBtn
} from './InterUIAccordion.styles';
import { InterUIIcon } from '../../core/InterUIIcon/InterUIIcon';

export const InterUIAccordion: React.FC<
  IInterUIAccordionProps & React.HtmlHTMLAttributes<HTMLElement>
> = ({
  id,
  margin,
  header,
  children,
  border = 'both',
  transitionIn,
  transitionOut,
  ...props
}) => {
  const theme = useTheme();

  const [toggle, setToggle] = useState(false);

  return (
    <Container margin={margin} border={border} {...props}>
      <Header id={id}>
        <HeaderBtn
          type='button'
          onClick={() => setToggle(!toggle)}
          toggle={toggle}
          aria-controls={`collapse-${id}`}
        >
          {header}

          <InterUIIcon
            name='arrow-chevron-down'
            size='md'
            color={theme.colors.primary.A500}
            className='collapse-arrow'
          />
        </HeaderBtn>
      </Header>

      <Collapse
        toggle={toggle}
        id={`collapse-${id}`}
        role='region'
        aria-labelledby={id}
        aria-expanded={toggle}
        transitionIn={transitionIn}
        transitionOut={transitionOut}
      >
        {children}
      </Collapse>
    </Container>
  );
};
