/**
 * Generates a random 36 length string
 * @returns - string
 */
export const generateUniqueIdentifier = () => '********-****-4***-_***-************'.replace(/[_*]/g, (char) => {
    const random = (Math.random() * 16) | 0;
    const parsedChar = char === '*' ? random : (random & 0x3) | 0x8;
    return parsedChar.toString(16);
});
/**
 * Allow classes to extend more than one class
 *
 * @param originalClass - Original class
 * @param extendsClass - Classes to extend
 */
export function applyMixins(originalClass, extendsClass) {
    extendsClass.forEach((extendClass) => {
        Object.getOwnPropertyNames(extendClass.prototype).forEach((name) => {
            const descriptor = Object.getOwnPropertyDescriptor(extendClass.prototype, name);
            Object.defineProperty(originalClass.prototype, name, descriptor);
        });
    });
}
