import { WbSessionActions } from 'enums';
import { InterWebviewBridge } from 'core';
const InterWbSession = () => {
    const iWb = InterWebviewBridge.getInstance();
    return {
        /**
         * * Retorna as informações do userInfo
         * @returns {IWbUserInfo}
         */
        getUserInfo() {
            const promise = iWb.execute({
                action: WbSessionActions.GET_USER_INFO,
            });
            return promise;
        },
        /**
         * Retorna as permissões autorizadas no momento
         * @returns {WbPermissions[]}
         */
        getPermissions() {
            const promise = iWb.execute({
                action: WbSessionActions.GET_PERMISSIONS,
            });
            return promise;
        },
        /**
         * Requisita uma permissão específica
         * @param permission - WbPermissions: STORAGE, CAMERA ou AUDIO
         * @returns
         */
        requestPermissions(permission) {
            const promise = iWb.execute({
                action: WbSessionActions.REQUEST_PERMISSION,
                metadata: { permission },
            });
            return promise;
        },
        /**
         *  Requisita informações do App
         * @returns {IWbAppInfo}
         */
        getAppInfo() {
            const promise = iWb.execute({
                action: WbSessionActions.GET_APP_INFO,
            });
            return promise;
        },
        /**
         *  Reinicia a seção para que o app não volte para o login
         * @returns void
         */
        resetSessionTime() {
            return iWb.execute({
                action: WbSessionActions.RESET_SESSION_TIME,
            });
        },
        /**
         * Faz o request de um Analytics
         * @param name - nome do evento
         * @param params - mapa chave/valor de parâmetros do evento
         * @returns
         */
        requestAnalytics(name, params) {
            const promise = iWb.execute({
                action: WbSessionActions.REQUEST_ANALYTICS,
                metadata: { name, params },
            });
            return promise;
        },
        /**
         * Envia evento TIC
         * @returns
         */
        tic(ticProps) {
            const promise = iWb.execute({
                action: WbSessionActions.TIC_EVENT,
                metadata: Object.assign({}, ticProps),
            });
            return promise;
        },
        /**
         * Envia evento TAC
         * @returns
         */
        tac(tacProps) {
            const promise = iWb.execute({
                action: WbSessionActions.TAC_EVENT,
                metadata: Object.assign({}, tacProps),
            });
            return promise;
        },
        /**
         * Edita a toolbar nativa
         * @param title - título da toolbar
         * @param show - esconder ou mostrar a toolbar
         * @returns
         */
        editToolbar(title, show) {
            const promise = iWb.execute({
                action: WbSessionActions.EDIT_TOOLBAR,
                metadata: { title, show },
            });
            return promise;
        },
        /**
         * Compartilha um texto no nativo
         *
         * @param text - texto plano a ser compartilhado
         * @returns void
         */
        shareContent(text) {
            return iWb.execute({
                action: WbSessionActions.SHARE_CONTENT,
                metadata: { text },
            });
        },
        /**
         * Requisita a localização atual do usuário
         *
         * @returns - IWbCurrentLocation
         */
        getUserLocation() {
            const promise = iWb.execute({
                action: WbSessionActions.GET_USER_LOCATION,
            });
            return promise;
        },
        /**
         *
         * @param key
         * @param data
         * @returns
         */
        saveOnStorage(key, data) {
            const promise = iWb.execute({
                action: WbSessionActions.SAVE_ON_STORAGE,
                metadata: {
                    key,
                    data,
                },
            });
            return promise;
        },
        /**
         * @returns
         */
        getFromStorage(key) {
            const promise = iWb.execute({
                action: WbSessionActions.GET_FROM_STORAGE,
                metadata: {
                    key,
                },
            });
            return promise;
        },
        /**
         * * Copia um texto para o clipboard
         * @param label - label para identificar a origem do texto
         * @param text - texto copiado
         * @returns - void
         */
        copyToClipboard(label, text) {
            return iWb.execute({
                action: WbSessionActions.COPY_TO_CLIPBOARD,
                metadata: { label, text },
            });
        },
    };
};
export const interWbSession = InterWbSession();
