import styled from 'styled-components'

import { interGridBreakpoints, orange, white, gray } from '../../styles/utils/variables'

export const FooterLanding = styled.footer`
  background-color: ${white};
  background-size: cover;
  color: gray;
  font-size: 14px;
  padding-bottom: 20px;
  padding-top: 20px;

  @media (min-width: ${interGridBreakpoints.sm}) {
    padding-bottom: 20px;
    text-align: center;
  }

  @media (min-width: ${interGridBreakpoints.md}) {
    padding: 60px 0 40px;
  }

  @media (min-width: ${interGridBreakpoints.lg}) {
    padding: 40px 0 0;
  }
  
  .list-inline-item{
    float: initial;
    margin: 0 auto;
    margin-top: 30px;
    
    &::before{
      border: none !important;
      margin-left: -10px;
    }
    @media (min-width: ${interGridBreakpoints.md}) {
      margin-top: 0px;
      float: left;
    }
  }

  svg {
    &.fill-gray {
      fill: ${gray[600]};
    }
    &.fill-orange, &:hover {
      fill: ${orange.base};
    }
  }
`
