export class InterWbLogger {
    constructor() {
        this.debug = false;
    }
    /**
     * Seta da variável debug
     * @param debug
     */
    setDebug(debug) {
        this.debug = debug;
    }
    /**
     * Retorna a variável debug
     * @returns boolean debug
     */
    isDebug() {
        return this.debug;
    }
    info(action, evitId, message) {
        this.debug && (console === null || console === void 0 ? void 0 : console.info(this.message(`${action}(${evitId}): ${message}`)));
    }
    error(action, evitId, message) {
        this.debug && (console === null || console === void 0 ? void 0 : console.error(this.message(`${action}(${evitId}): ${message}`)));
    }
    message(data) {
        return `InterWebviewBridge - ${data}`;
    }
}
