import styled from 'styled-components';

/**
 * Div principal.
 */
export const Container = styled.div`
  padding-top: 40px;
  width: 100%;
`;

/**
 * Div principal do slide.
 */
export const SlideContent = styled.div`
  margin: 0 24px;
  text-align: center;
`;

/**
 * Imagem do slide.
 */
export const Image = styled.img``;

/**
 * Ícone do slide.
 */
export const Icon = styled.div``;

/**
 * Título do conteúdo do slide.
 */
export const Title = styled.h1`
  padding-top: 24px;
  text-align: start;
  color: ${(props) => props.theme.colors.neutral.grayscale.A500};
`;

/**
 * Texto do conteúdo do slide.
 */
export const Description = styled.p`
  min-height: 82px;
  padding-top: 8px;
  font-weight: 400;
  font-size: 14px;
  text-align: start;
  color: ${(props) => props.theme.colors.neutral.grayscale.A400};
`;

/**
 * Div principal do controle de navegação.
 */
export const NavigationContent = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 20px 0;
`;

/**
 * Botão Anterior do controle da navegação.
 */
export const ButtonPrev = styled.button`
  width: 90%;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.primary.A500};
  text-align: start;
  padding-left: 24px;
`;

/**
 * Botão Próximo do controle da navegação.
 */
export const ButtonNext = styled.button`
  width: 90%;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.primary.A500};
  text-align: end;
  padding-right: 24px;
`;

/**
 * Botão Pular do controle da navegação.
 */
export const ButtonSkip = styled.button<{
  skipVertical?: string;
  skipHorizontal?: string;
}>`
  position: fixed;
  top: ${(props) => props.skipVertical || `0`};
  right: ${(props) => props.skipHorizontal || `0`};
  z-index: 90;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.primary.A500};
`;

/**
 * Div central da navegação referente aos dots.
 */
export const DotsControl = styled.div<{ type: string }>`
  width: 100%;
  text-align: ${(props) => (props.type === 'default' ? 'center' : 'start')};
  padding-left: ${(props) => (props.type === 'default' ? '0px' : '20px')};
`;

/**
 * Span referente ao dot.
 */
export const Dot = styled.span`
  display: inline-block;
  width: 24px;
  height: 2px;
  margin: 0 4px;
  background-color: ${(props) => props.theme.colors.neutral.grayscale.A200};
  border-radius: 1px;
  transition: background-color 0.6s ease;

  &.active {
    background-color: ${(props) => props.theme.colors.primary.A500};
  }
`;
