import React from 'react';

import * as S from './InterUITimeline.styles';
import { TimelineItem } from './TimelineItem';
import { PropsWithRequiredChildren } from '../../interfaces/inter-ui-timeline-props';

export const InterUITimeline = ({
  children: childrenProp,
  ...attrs
}: PropsWithRequiredChildren) => {
  const itemsCount = React.Children.count(childrenProp);

  const children = React.Children.map(childrenProp, (child, index) => {
    if (React.isValidElement(child)) {
      const childProps = {
        hasVerticalBar: index !== itemsCount - 1
      };
      return React.cloneElement(child, childProps);
    }
    return child;
  });

  return <S.TimelineContainer {...attrs}>{children}</S.TimelineContainer>;
};

InterUITimeline.Item = TimelineItem;
