import React, { useEffect } from 'react';

import { IInterUISnackbarProps } from '../../interfaces/inter-ui-snackbar-props';
import {
  Container,
  Content,
  FullLink,
  FullText,
  LinkContent,
  Snackbar,
  TextContent
} from './InterUISnackbar.styles';

/**
 * Componente Inter UI Snackbar.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUISnackbar: React.FC<
  IInterUISnackbarProps & React.HtmlHTMLAttributes<HTMLElement>
> = ({
  message,
  linkText,
  noDuration = false,
  duration = 2000,
  toggle = false,
  onClickedLink,
  onHide,
  ...props
}) => {
  const MAX_CHARACTERS_TO_BREAK = 40;

  let checkToggle: NodeJS.Timeout;

  /**
   * Determina a visibilidade do componente por uma duração especifica definida pelo props duration.
   */
  const handleVisibility = () => {
    clearTimeout(checkToggle);

    if (toggle) {
      checkToggle = setTimeout(() => {
        onHide();
      }, duration);
    }
  };

  useEffect(() => {
    if (noDuration) {
      return;
    }

    handleVisibility();
  }, [toggle]);

  const render = () => {
    if (!linkText) {
      noDuration = false;

      return (
        <Snackbar role='snackbar' aria-hidden={!toggle} default>
          <Content type='default'>
            <TextContent>{message}</TextContent>
          </Content>
        </Snackbar>
      );
    } else if (
      linkText &&
      message.length + linkText.length <= MAX_CHARACTERS_TO_BREAK
    ) {
      return (
        <Snackbar role='snackbar' aria-hidden={!toggle}>
          <Content type='link'>
            <TextContent>{message}</TextContent>
            <LinkContent type='button' onClick={onClickedLink}>
              <TextContent>{linkText}</TextContent>
            </LinkContent>
          </Content>
        </Snackbar>
      );
    } else {
      return (
        <Snackbar role='snackbar' aria-disabled={!toggle}>
          <Content type='full'>
            <FullText>
              <TextContent>{message}</TextContent>
            </FullText>
            <FullLink>
              <LinkContent type='button' onClick={onClickedLink}>
                <TextContent>{linkText}</TextContent>
              </LinkContent>
            </FullLink>
          </Content>
        </Snackbar>
      );
    }
  };

  return (
    <Container {...props} toggle={toggle}>
      {toggle && render()}
    </Container>
  );
};
