import React from 'react';

import { IInterUIListsProps } from '../../interfaces/inter-ui-lists-props';
import { Container, Content, Icon, Value, Title } from './InterUIList.styles';

/**
 * Componente Inter UI Lists.
 * @param props Propriedades disponíveis para definição do layout.
 */

export const InterUIList: React.FC<
  IInterUIListsProps & React.HtmlHTMLAttributes<HTMLElement>
> = ({
  margin,
  variant = 'horizontal',
  text,
  value,
  icon,
  align,
  ...props
}) => {
  return (
    <Container margin={margin} {...props}>
      <Content
        variant={variant}
        align={align}
        data-testid='inter-ui-list-content'
      >
        <Title>
          {text}

          <Icon data-testid='inter-ui-list-help-icon'>{icon}</Icon>
        </Title>

        <Value variant={variant} data-testid='inter-ui-list-value'>
          {value}
        </Value>
      </Content>
    </Container>
  );
};
