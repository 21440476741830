import styled, { keyframes } from 'styled-components';
import { ProgressBarProps, ProgressCircleProps } from '../../interfaces/inter-ui-progress-bar-props';

/**
 * Div referente a barra.
 */
export const Bar = styled.div<ProgressBarProps>`
  flex: 1;
  height: ${(props) => props.height || '2px'};
  ${(props) => props.margin && `margin: ${props.margin};`}
  background-color: ${(props) =>
    props.barColor || props.theme.colors.neutral.grayscale.A100};
  ${(props) => props.rounded && `border-radius: 50px;`}
`;

/**
 * Div referente ao progresso.
 */
export const Progress = styled.span<ProgressBarProps>`
  display: block;
  width: ${(props) => props.progress || `0%`};
  height: 100%;
  background-color: ${(props) =>
    props.progressColor || props.theme.colors.primary.A500};
  transition: width 0.5s;
  ${(props) => props.rounded && `border-radius: 50px;`}
`;

/**
 * Animação barra de progresso.
 * Para a animação será feito um calculo baseado no valor do stroke-dashoffset atual em 'px'.
 * @param progress valor do progresso.
 */
const progressAnimation = (
  progress?: string
) => keyframes`
  100% { stroke-dashoffset: calc(190px - 190px * ${progress}/100); }
`;

/**
 * Div referente ao circulo.
 */
export const Circle = styled.div<ProgressCircleProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size || '48px'};
  height: ${(props) => props.size || '48px'};
  ${(props) => props.margin && `margin: ${props.margin};`}
`

/**
 * Div referente ao .
 */
export const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(270deg);
`;

/**
 * Elemento que representa o progresso do circulo.
 */

export const HalfCircle = styled.circle<ProgressCircleProps>`
  stroke-width: ${(props) => props.strokeWidth || 6};
  stroke: ${(props) => props.progressColor || props.theme.colors.feedback.success.A500};
  stroke-dasharray: 190;
  stroke-dashoffset: 190;
  animation: ${(props) => progressAnimation(props.progress)} 1.5s linear forwards;
`;

/**
 * Elemento que representa o círculo completo.
 */
export const FullCircle = styled.circle<ProgressCircleProps>`
  stroke-width: ${(props) => props.strokeWidth || 6};
  stroke: ${(props) => props.circleColor || props.theme.colors.neutral.grayscale.A200};
`;
