import React from 'react';

import { IInterUISkeletonProps } from '../../interfaces/inter-ui-skeleton-props';
import { Skeleton } from './InterUISkeleton.styles';

/**
 * Componente Inter UI Skeleton.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUISkeleton: React.FC<
  IInterUISkeletonProps & React.HTMLAttributes<HTMLDivElement>
> = ({ width, height, marginBottom, animation, variant, ...props }) => {
  return (
    <Skeleton
      width={width}
      height={height}
      marginBottom={marginBottom}
      animation={animation}
      variant={variant}
      data-testid='inter-ui-skeleton'
      {...props}
    />
  );
};
