import { css } from 'styled-components'

export function generateFontSizeClasses (fontSizes, gridBreakpoints) {
  return (
    Object.keys(fontSizes).map(size => (
      css`
        .fs-footer-${size} {
          font-size: ${size}px;
        }
        ${Object.keys(gridBreakpoints).map(breakpoint => (
        css`
          @media (min-width: ${gridBreakpoints[breakpoint]}) {
            .fs-footer-${breakpoint}-${size} {
              font-size: ${size}px;
            }
          }
        `
      ))}
      `
    ))
  )
}

export function generateFontWeightClasses (fontWeight) {
  return (
    Object.keys(fontWeight).map(weight => (
      css`
        .fw-footer-${weight} { font-weight: ${weight} !important; }
      `
    ))
  )
}
