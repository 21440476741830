import styled, { keyframes } from 'styled-components';

/**
 * Animação dots.
 * @param colorDots Cor do dots.
 * @param dotLeft Posição do dot a esquerda. Tamanho do container - largura do dot - valor desejado
 * @param dotCenter Tamanho do container
 * @param dotRight Posição do dot a direita. Tamanho do container - largura do dot - valor desejado
 * @param heightAnimation Altura do top da animação
 */
const dotsAnimation = (
  colorDots?: string,
  dotLeft?: string,
  dotCenter?: string,
  dotRight?: string,
  heightAnimation?: string
) => keyframes`
  0% {box-shadow: ${dotLeft} 0 0 0 ${colorDots}, ${dotCenter} 0 0 0 ${colorDots}, ${dotRight} 0 0 0 ${colorDots}}
  16% {box-shadow: ${dotLeft} ${heightAnimation} 0 0 ${colorDots}, ${dotCenter} 0 0 0 ${colorDots}, ${dotRight} 0 0 0 ${colorDots};}
  33% {box-shadow: ${dotLeft} 0 0 0 ${colorDots}, ${dotCenter} 0 0 0 ${colorDots}, ${dotRight} 0 0 0 ${colorDots};}
  50% {box-shadow: ${dotLeft} 0 0 0 ${colorDots}, ${dotCenter} ${heightAnimation} 0 0 ${colorDots}, ${dotRight} 0 0 0 ${colorDots};}
  66% {box-shadow: ${dotLeft} 0 0 0 ${colorDots}, ${dotCenter} 0 0 0 ${colorDots}, ${dotRight} 0 0 0 ${colorDots};}
  83% {box-shadow: ${dotLeft} 0 0 0 ${colorDots}, ${dotCenter} 0 0 0 ${colorDots}, ${dotRight} ${heightAnimation} 0 0 ${colorDots};}
  100% {box-shadow: ${dotLeft} 0 0 0 ${colorDots}, ${dotCenter} 0 0 0 ${colorDots}, ${dotRight} 0 0 0 ${colorDots};}
`;

/**
 * Animação checkmark.
 */
const checkAnimation = keyframes`
 50% {transform: rotate(45deg) scale(1.5)}
 100% {transform: rotate(45deg) scale(1)}
`;

/**
 * Elemento que engloba os dots.
 */
export const DotsContent = styled.div<{ bgColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 20px;
  background-color: ${(props) =>
    props.bgColor || props.theme.colors.primary.A200};
  border-radius: 100px;
`;

/**
 * Elemento que representa os dots.
 */
export const DotTyping = styled.div<{
  colorDots?: string;
  dotLeft?: string;
  dotCenter?: string;
  dotRight?: string;
  width?: string;
  height?: string;
  heightAnimation?: string;
}>`
  position: relative;
  left: -100%;
  width: ${(props) => props.width || '4px'};
  height: ${(props) => props.height || '4px'};
  border-radius: 50%;
  animation: ${(props) =>
      dotsAnimation(
        props.colorDots || props.theme.colors.primary.A500,
        props.dotLeft || '28px',
        props.dotCenter || '36px',
        props.dotRight || '44px',
        props.heightAnimation || '-4px'
      )}
    1.5s infinite linear;
`;

/**
 * Elemento que representa o checkmark.
 */

export const DotCheck = styled.div<{
  checkmarkColor?: string;
  checkmarkTop?: string;
}>`
  position: relative;
  ${(props) => props.checkmarkTop && `top:${props.checkmarkTop}`};
  width: 4px;
  height: 6px;
  transform: rotate(45deg) scale(1);
  transform-origin: center right;
  animation: ${checkAnimation} forwards 0.5s linear;

  ::before,
  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: ${(props) =>
      props.checkmarkColor || props.theme.colors.primary.A500};
    border-radius: 10px;
  }

  ::before {
    width: 100%;
    height: 2px;
  }

  ::after {
    width: 2px;
    height: 100%;
  }
`;
