import React from 'react';

import { IInterUIRadioProps } from '../../interfaces/inter-ui-radio-props';
import {
  Container,
  InputRadioHidden,
  LabelContent,
  RadioCircle,
  RadioContent
} from './InterUIRadio.styles';

type InputProps = IInterUIRadioProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;

/**
 * Componente Inter UI RadioButtton.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUIRadio = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      variant,
      radioPosition,
      margin,
      padding,
      border,
      color,
      children,
      ref,
      ...props
    },
    register
  ) => {
    return (
      <Container
        data-testid='inter-ui-radio'
        htmlFor={props.id}
        margin={margin}
        padding={padding}
        variant={variant}
        radioPosition={radioPosition}
        border={border}
        checked={props.checked}
        disabled={props.disabled}
      >
        <LabelContent
          data-testid='inter-ui-radio-content'
          variant={variant}
          disabled={props.disabled}
        >
          {children}
        </LabelContent>

        <RadioContent variant={variant} radioPosition={radioPosition}>
          <InputRadioHidden
            ref={register}
            color={color}
            data-testid='inter-ui-radio-hidden'
            {...props}
          />

          <RadioCircle color={color} />
        </RadioContent>
      </Container>
    );
  }
);
