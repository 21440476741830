import React from 'react'

import AppStorePT from 'inter-frontend-svgs/lib/v2/social/store/app-store'
import PlayStorePT from 'inter-frontend-svgs/lib/v2/social/store/google-play'

import { ITStoreProps } from '../../Footer.interface'

const Store: React.FC<ITStoreProps> = ({ t, sendDatalayerFooterEvent, isPj }: ITStoreProps): React.ReactElement => {
  const AppStoreUrl = 'https://itunes.apple.com/br/app/banco-inter/id839711154'
  const GooglePlayUrl = 'https://play.google.com/store/apps/details?id=br.com.intermedium'
  const AppStoreUrlPj = 'https://itunes.apple.com/br/app/banco-inter/id1439198315'
  const GooglePlayUrlPj = 'https://play.google.com/store/apps/details?id=br.com.Inter.CDPro'

  return (
    <>
      <span className='title-font fw-footer-700 mb-3 fs-footer-16 mt-3 mt-lg-0'>{t('get_app')}</span>
      <div className='d-flex d-lg-block justify-content-start text-left flex-lg-column pb-4 pb-md-0'>
        <div className='col col-lg-12 mb-2 pl-0'>
          <a
            onClick={() => {
              sendDatalayerFooterEvent({
                section: t('get_app'),
                element_action: 'click button',
                element_name: 'App Store',
                redirect_url: isPj ? AppStoreUrlPj : AppStoreUrl,
                c_page: window.location.href,
              })
            }}
            href={isPj ? AppStoreUrlPj : AppStoreUrl}
            target='_blank'
            rel='noopener noreferrer'
            title='Baixe na App Store'
            aria-label='App Store'
          >
            <AppStorePT width='150' height='45' />
          </a>
        </div>
        <div className='col col-lg-12 mb-2 pl-0'>
          <a
            onClick={() => {
              sendDatalayerFooterEvent({
                section: t('get_app'),
                element_action: 'click button',
                element_name: 'Google Play',
                redirect_url: isPj ? GooglePlayUrlPj : GooglePlayUrl,
                c_page: window.location.href,
              })
            }}
            href={isPj ? GooglePlayUrlPj : GooglePlayUrl}
            target='_blank'
            rel='noopener noreferrer'
            title='Baixe no Google Play'
            aria-label='Google Play'
          >
            <PlayStorePT width='150' height='45' />
          </a>
        </div>
      </div>
    </>
  )
}

export default Store
