import React from 'react';

import { Container, LeftContent, RightContent } from './InterUIBanner.styles';
import { IInterUIBannerProps } from '../../interfaces/inter-ui-banner-props';

/**
 * Componente Inter UI Banner.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUIBanner: React.FC<
  IInterUIBannerProps & React.HtmlHTMLAttributes<HTMLElement>
> = ({ backgroundColor, fullImg, imgRight, children, ...props }) => {
  return (
    <Container
      backgroundColor={backgroundColor}
      data-testid='inter-ui-banner'
      {...props}
    >
      <LeftContent>{children}</LeftContent>
      <RightContent fullImg={fullImg} data-testid='right-content'>
        <img src={imgRight} alt='imagem banner' />
      </RightContent>
    </Container>
  );
};
