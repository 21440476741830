import styled, { keyframes } from 'styled-components';

import { IInterUILoadingProps } from '../../interfaces/inter-ui-loading-props';

/**
 * Animação para girar o elemento.
 */
const rotatorAnimation = keyframes`
  100% { transform: rotate(360deg); }
`;

/**
 * Svg que representa o ícone.
 */
export const Svg = styled.svg`
  animation: ${rotatorAnimation} 1s linear infinite;
`;

/**
 * Elemento que presenta o círculo menor.
 */
export const HalfCircle = styled.circle<IInterUILoadingProps>`
  stroke-width: ${(props) => props.strokeWidth || 6};
  stroke: ${(props) => props.halfColor || props.theme.colors.primary.A500};
  stroke-dasharray: 187;
  stroke-dashoffset: 93.5;
`;

/**
 * Elemento que representa o círculo completo.
 */
export const FullCircle = styled.circle<IInterUILoadingProps>`
  stroke-width: ${(props) => props.strokeWidth || 6};
  stroke: ${(props) => props.fullColor || props.theme.colors.primary.A200};
`;
