import styled, { css } from 'styled-components';

import { TimelineType } from '../../interfaces/inter-ui-timeline-props';

type TypeProps = {
  type: TimelineType;
};

const colorByType = css<TypeProps>`
  ${(props) => props.type === 'Start' && props.theme.colors.neutral.grayscale.A500}
  ${(props) => props.type === 'Success' && props.theme.colors.feedback.success.A500}
  ${(props) => props.type === 'Error' && props.theme.colors.feedback.error.A500}
  ${(props) => props.type === 'Warning' && props.theme.colors.feedback.warning.A500}
  ${(props) => props.type === 'Disabled' && props.theme.colors.neutral.grayscale.A300}
`;

export const TimelineContainer = styled.ul``;

export const TimelineItemContainer = styled.li`
  position: relative;
  display: flex;
  margin: 0 0 2px;
  list-style: none;
`;

export const CustomIconContainer = styled.div<TypeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border: 2px solid ${colorByType};
  border-radius: 50%;
`;

export const Dot = styled.span<TypeProps>`
  position: absolute;
  ${(props) =>
    props.type === 'Start'
      ? `top: 6px; left: 6px; border-width: 4px; border-style: solid;`
      : `width: 16px; height: 16px; border-width: 2px; border-style: solid;`};
  border-color: ${colorByType};
  border-radius: 50%;
  display: inline-block;
`;

export const VerticalBar = styled.div<TypeProps>`
  position: absolute;
  top: 22px;
  left: 9px;
  background-color: ${colorByType};
  border-radius: 1px;
  width: 2px;
  height: calc(100% - 22px);
`;

export const Text = styled.span<{ color: string; bold?: boolean }>`
  font-family: 'Inter';
  font-size: 12px;
  margin: 0;
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.neutral.grayscale.A400};
  ${(props) => props.bold && `font-weight: bold;`}

  p {
    font-size: inherit;
  }
`;
