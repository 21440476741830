import styled, { css } from 'styled-components';

import { IInterUIButtonProps } from '../../interfaces/inter-ui-button-props';

/**
 * Div principal.
 */
export const Container = styled.div<IInterUIButtonProps>`
  margin: ${(props) => props.margin || `0`};
  text-align: center;
`;

/**
 * Button com o layout default.
 */
const Button = styled.button<IInterUIButtonProps>`
  min-width: 220px;
  width: ${(props) => props.width || `100%`};
  min-height: ${(props) => (props.small ? `32px` : `48px`)};
  padding: ${(props) => (props.small ? `0px 16px` : `0px 24px`)};
  border-radius: 8px;
  transition: 0.3s;

  &,
  p {
    font-size: ${(props) => (props.small ? `0.625rem` : `0.875rem`)};
    font-weight: 700;
    line-height: ${(props) => (props.small ? `0.75rem` : `1.063rem`)};
    text-align: center;
  }

  ${(props) => props.alignContent === 'space-between' && `${JustifyContent}`}
`;

/**
 * Variação primária.
 */
export const Primary = styled(Button)`
  background: ${(props) => props.theme.colors.primary.A500};
  color: ${(props) => props.theme.colors.base.white};

  &:disabled {
    background: ${(props) => props.theme.colors.neutral.grayscale.A200};
    color: ${(props) => props.theme.colors.neutral.grayscale.A300};
  }
`;

/**
 * Variação secundária.
 */
export const Secondary = styled(Button)`
  border: 1px solid ${(props) => props.theme.colors.primary.A300};
  color: ${(props) => props.theme.colors.primary.A500};

  &:disabled {
    border-color: ${(props) => props.theme.colors.neutral.grayscale.A200};
    color: ${(props) => props.theme.colors.neutral.grayscale.A300};
  }
`;

/**
 * Variação primária negativa.
 */
export const PrimaryNegative = styled(Button)`
  background: ${(props) => props.theme.colors.base.white};
  color: ${(props) => props.theme.colors.primary.A500};

  &:disabled {
    background: ${(props) => props.theme.colors.opacity.A200};
    color: ${(props) => props.theme.colors.opacity.A400};
  }
`;

/**
 * Variação secundária negativa.
 */
export const SecondaryNegative = styled(Button)`
  border: 1px solid ${(props) => props.theme.colors.opacity.A500};
  color: ${(props) => props.theme.colors.base.white};

  &:disabled {
    border-color: ${(props) => props.theme.colors.opacity.A300};
    color: ${(props) => props.theme.colors.opacity.A300};
  }
`;

/**
 * Variação do botão com elementos nas extremidades.
 */
const JustifyContent = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

/**
 * Div que engloba o svg.
 */
export const SvgContainer = styled.div`
  width: 100%;
`;
