import { addLeadingZeros } from './add-leading-zeros'

export const dateToObject = (date: Date) => {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return {
    day,
    month,
    year,
    formatted: `${addLeadingZeros({ value: day })}/${addLeadingZeros({
      value: month,
    })}/${year}`,
  }
}
