import React from 'react';
import { useTheme } from 'styled-components';

import { InterUILoading } from '../../components/InterUILoading/InterUILoading';
import { IInterUIButtonProps } from '../../interfaces/inter-ui-button-props';
import {
  Container,
  Primary,
  PrimaryNegative,
  Secondary,
  SecondaryNegative,
  SvgContainer
} from './InterUIButton.styles';

/**
 * Componente Inter UI Botão.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUIButton: React.FC<
  IInterUIButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ className, children, loading, ...props }) => {
  const theme = useTheme();

  const renderButtonVariant = {
    primary: () => (
      <Primary {...props}>
        {loading ? (
          <SvgContainer role='status'>
            <InterUILoading
              size={props.small ? 'xs' : undefined}
              halfColor={theme.colors.base.white}
              fullColor={
                props.disabled
                  ? theme.colors.neutral.grayscale.A300
                  : theme.colors.primary.A400
              }
            />
          </SvgContainer>
        ) : (
          children
        )}
      </Primary>
    ),
    secondary: () => (
      <Secondary {...props}>
        {loading ? (
          <SvgContainer role='status'>
            <InterUILoading
              size={props.small ? 'xs' : undefined}
              halfColor={
                props.disabled
                  ? theme.colors.neutral.grayscale.A200
                  : theme.colors.primary.A200
              }
              fullColor={
                props.disabled
                  ? theme.colors.neutral.grayscale.A300
                  : theme.colors.primary.A300
              }
            />
          </SvgContainer>
        ) : (
          children
        )}
      </Secondary>
    ),
    primaryNegative: () => (
      <PrimaryNegative {...props}>
        {loading ? (
          <SvgContainer role='status'>
            <InterUILoading
              size={props.small ? 'xs' : undefined}
              halfColor={
                props.disabled
                  ? theme.colors.opacity.A500
                  : theme.colors.neutral.grayscale.A200
              }
              fullColor={
                props.disabled
                  ? theme.colors.opacity.A300
                  : theme.colors.primary.A500
              }
            />
          </SvgContainer>
        ) : (
          children
        )}
      </PrimaryNegative>
    ),
    secondaryNegative: () => (
      <SecondaryNegative {...props}>
        {loading ? (
          <SvgContainer role='status'>
            <InterUILoading
              size={props.small ? 'xs' : undefined}
              halfColor={
                props.disabled
                  ? theme.colors.opacity.A500
                  : theme.colors.primary.A200
              }
              fullColor={
                props.disabled
                  ? theme.colors.opacity.A300
                  : theme.colors.primary.A300
              }
            />
          </SvgContainer>
        ) : (
          children
        )}
      </SecondaryNegative>
    )
  };

  const render = renderButtonVariant[props.variant || 'primary'];

  return (
    <Container
      data-testid='inter-ui-button'
      className={className}
      variant={props.variant}
      margin={props.margin}
    >
      {render()}
    </Container>
  );
};
