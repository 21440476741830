import i18n from 'i18next'

import PTFooter from '../../locales/pt/Footer.json'
import ENFooter from '../../locales/en/Footer.json'

/**
 * Pega a linguagem padrão da janela.
 */

const urlLanguage = typeof window !== 'undefined' && window.location.pathname.match(/(\/)(\w{2})/)
const language = urlLanguage ? urlLanguage[0].replace(/\//g, '') : 'pt'

const resources = {
  pt: {
    Footer: PTFooter,
  },
  en: {
    Footer: ENFooter,
  },
}

const footerI18n = i18n.createInstance()

footerI18n
  .init({
    fallbackLng: 'pt',
    lng: language,

    // have a common namespace used around the full app
    ns: [ 'Footer' ],
    defaultNS: 'Globals',

    resources,

    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    cache: {
      enabled: true,
    },

    load: 'languageOnly',

    whitelist: [ 'pt', 'en' ],

    react: {
      wait: true,
    },
  })

export default footerI18n
