import React from 'react';

import { IInterUIProgressBarProps, ProgressBarProps, ProgressCircleProps } from '../../interfaces/inter-ui-progress-bar-props';
import { Bar, Circle, FullCircle, HalfCircle, Progress, Svg } from './InterUIProgressBar.styles';


const ProgressBarContent: React.FC<ProgressBarProps> = ({
  progress, rounded, progressColor, ...props
}) => {
  return (
    <Bar data-testid='inter-ui-progress-bar' rounded={rounded} {...props}>
      <Progress
        role='progressbar'
        progress={progress}
        rounded={rounded}
        progressColor={progressColor}
      >
      </Progress>
    </Bar>
  );
};

const ProgressCircleContent: React.FC<ProgressCircleProps> = ({ size, strokeWidth, progressColor, circleColor, progress, children, ...props
}) => {
  return (
    <Circle data-testid='inter-ui-progress-circle' size={size} {...props}>
      {children}
      <Svg
        width={size}
        height={size}
        viewBox='0 0 66 66'
        xmlns='http://www.w3.org/2000/svg'
      >
        <FullCircle
          fill='none'
          cx='33'
          cy='33'
          r='30'
          strokeWidth={strokeWidth}
          circleColor={circleColor}
        />
        <HalfCircle
          progress={progress}
          fill='none'
          cx='33'
          cy='33'
          r='30'
          strokeLinecap='round'
          strokeWidth={strokeWidth}
          progressColor={progressColor}
          role='progressbar'
        />
      </Svg>
    </Circle>
  );
};

/**
 * Componente Inter UI Progress Bar.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUIProgressBar: React.FC<IInterUIProgressBarProps> = ({ status = 'bar', ...props }) => {
  return (
    <React.Fragment>
      {status === 'bar' && (
        <ProgressBarContent {...props} />
      )}

      {status === 'circle' && (
        <ProgressCircleContent {...props} />
      )}
    </React.Fragment>
  );
};
