import styled from 'styled-components';

/**
 * Div principal.
 */
export const Container = styled.div<{ margin?: string }>`
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;

  ${(props) => props.margin && `margin: ${props.margin};`}

  .inter-ui-file-input {
    display: block;
    width: 100%;
  }
`;

/**
 * Container flex row.
 */
export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

/**
 * Container flex column.
 */
export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

/**
 * Conteúdo do card.
 */
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

/**
 * Título do card.
 */
export const Title = styled.p`
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${(props) => props.theme.colors.primary.A500};
`;

/**
 * Título do card simples.
 */
export const TitleSimple = styled.p`
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${(props) => props.theme.colors.neutral.grayscale.A500};
`;

/**
 * Subtítulo do card.
 */
export const Subtitle = styled.p<{ marginBottom?: string }>`
  font-size: 10px;
  line-height: 12px;
  color: ${(props) => props.theme.colors.neutral.grayscale.A400};
  margin-bottom: ${(props) => props.marginBottom || `8px`};
`;

/**
 * Texto do arquivo no card simples.
 */
export const Filename = styled.p`
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.neutral.grayscale.A500};
`;

/**
 * Placeholder para a prévisualização da imagem.
 */
export const ImagePlaceholder = styled.div`
  width: 64px;
  height: 64px;
  background-color: ${(props) => props.theme.colors.neutral.grayscale.A100};
  border-radius: 8px;
  margin-right: 16px;
  flex-shrink: 0;
  flex-grow: 0;
`;

/**
 * Prévisualização da imagem.
 */
export const ImagePreview = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 8px;
  margin-right: 16px;
  flex-shrink: 0;
  flex-grow: 0;
  object-fit: cover;
`;

/**
 * Container para os ícones de ações do card.
 */
export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-left: 16px;
  }
`;

/**
 * Container para o conteúdo de texto
 */
export const TextContent = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;
