import React from 'react'
import { TPrivacyCertificationProps } from '../../Footer.interface'

import BureauVeritas from '../../../Ib/images/bureau-veritas/BureauVeritas'

const PrivacyCertification: React.FC<TPrivacyCertificationProps> = ({ t }: TPrivacyCertificationProps): React.ReactElement => {
  return (
    <>
      <span className='title-font fw-footer-700 mb-2 fs-footer-15 mt-lg-0'>{t('privacy_certification')}</span>
      <div>
        <BureauVeritas />
      </div>
    </>
  )
}

export default PrivacyCertification
