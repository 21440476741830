import { IFullColors } from "./inter"

export interface IPrimaryColors {
  primary: IFullColors
}

export const primaryLight: IPrimaryColors = {
  primary: {
    A500: '#FF7A00',
    A400: '#FF9D42',
    A300: '#FFB46E',
    A200: '#FFDAB7',
    A100: '#FFF2E7'
  }
}

export const primaryDark: IPrimaryColors = {
  primary: {
    A500: '#FF7A00',
    A400: '#B65C0A',
    A300: '#904C0F',
    A200: '#553417',
    A100: '#2E241C'
  }
}

export const primaryLightPJ: IPrimaryColors = {
  primary: {
    A500: '#07605B',
    A400: '#409B97',
    A300: '#80BCBA',
    A200: '#BFDEDD',
    A100: '#E5F2F1'
  }
}

export const primaryDarkPJ: IPrimaryColors = {
  primary: {
    A500: '#399490',
    A400: '#327775',
    A300: '#2C5F5E',
    A200: '#243B3C',
    A100: '#1F2829'
  }
}


