import { WbResponseError, WbHttpError } from 'errors';
export class InterWbResponseHandler {
    wbResponseHandler(event) {
        const { error, data, action } = event.detail;
        try {
            if (error && !(data === null || data === void 0 ? void 0 : data.httpStatus)) {
                throw new WbResponseError(error, action);
            }
            if ((data === null || data === void 0 ? void 0 : data.httpStatus) && data.httpStatus >= 400) {
                throw new WbHttpError(data.response, data.httpStatus, action);
            }
            let parsedData = {};
            if (data) {
                parsedData = typeof data === 'object' ? data : JSON.parse(data);
            }
            return parsedData;
        }
        catch (err) {
            if (err instanceof WbHttpError) {
                throw JSON.stringify({
                    action: err.action,
                    message: 'Erro na Api.',
                    httpStatus: err.httpStatus,
                    response: err.response,
                });
            }
            else if (err instanceof WbResponseError) {
                throw JSON.stringify({ action: err.action, message: err.message });
            }
            else {
                throw err;
            }
        }
    }
}
