import { useState, useEffect } from 'react';

export interface Column {
  classes: string;
  date: string;
  value: number;
}

interface CalendarRows {
  [id: number]: Column[];
}

const daysShortArr = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'];

const monthNamesArr = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
];

const useCalendar = (
  prevRange?: number,
  nextRange?: number,
  date?: Date,
  daysShort = daysShortArr,
  monthNames = monthNamesArr
) => {
  const [counterNext, setCounterNext] = useState(1);
  const [counterPrev, setCounterPrev] = useState(1);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [prevButtonDisabled, setPrevButtonDisabled] = useState(false);

  useEffect(() => {
    prevRange === 0
      ? setPrevButtonDisabled(true)
      : setPrevButtonDisabled(false);
    nextRange === 0
      ? setNextButtonDisabled(true)
      : setNextButtonDisabled(false);
  }, []);

  const today = date || new Date();
  const todayFormatted = `${('0' + today.getDate()).slice(-2)}/${(
    '0' +
    (today.getMonth() + 1)
  ).slice(-2)}/${today.getFullYear()}`;
  const daysInWeek = [1, 2, 3, 4, 5, 6, 0];
  const [selectedDate, setSelectedDate] = useState(today);
  const selectedMonthLastDate = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth() + 1,
    0
  );
  const prevMonthLastDate = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    0
  );
  const daysInMonth = selectedMonthLastDate.getDate();
  const firstDayInMonth = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    1
  ).getDay();
  const startingPoint = daysInWeek.indexOf(firstDayInMonth) + 1;
  let prevMonthStartingPoint =
    prevMonthLastDate.getDate() - daysInWeek.indexOf(firstDayInMonth) + 1;
  let currentMonthCounter = 1;
  let nextMonthCounter = 1;
  const rows = 6;
  const cols = 7;
  const calendarRows: CalendarRows = {};

  for (let i = 1; i < rows + 1; i++) {
    for (let j = 1; j < cols + 1; j++) {
      if (!calendarRows[i]) {
        calendarRows[i] = [];
      }

      if (i === 1) {
        if (j < startingPoint) {
          calendarRows[i] = [
            ...calendarRows[i],
            {
              classes: 'in-prev-month',
              date: `PrevDate: ${prevMonthStartingPoint}-${
                selectedDate.getMonth() === 0 ? 12 : selectedDate.getMonth()
              }-${
                selectedDate.getMonth() === 0
                  ? selectedDate.getFullYear() - 1
                  : selectedDate.getFullYear()
              }`,
              value: prevMonthStartingPoint
            }
          ];
          prevMonthStartingPoint++;
        } else {
          calendarRows[i] = [
            ...calendarRows[i],
            {
              classes: '',
              date: `${('0' + currentMonthCounter).slice(-2)}/${(
                '0' +
                (selectedDate.getMonth() + 1)
              ).slice(-2)}/${selectedDate.getFullYear()}`,
              value: currentMonthCounter
            }
          ];
          currentMonthCounter++;
        }
      } else if (i > 1 && currentMonthCounter < daysInMonth + 1) {
        calendarRows[i] = [
          ...calendarRows[i],
          {
            classes: '',
            date: `${('0' + currentMonthCounter).slice(-2)}/${(
              '0' +
              (selectedDate.getMonth() + 1)
            ).slice(-2)}/${selectedDate.getFullYear()}`,
            value: currentMonthCounter
          }
        ];
        currentMonthCounter++;
      } else {
        calendarRows[i] = [
          ...calendarRows[i],
          {
            classes: 'in-next-month',
            date: `NextDate: ${nextMonthCounter}-${
              selectedDate.getMonth() + 2 === 13
                ? 1
                : selectedDate.getMonth() + 2
            }-${
              selectedDate.getMonth() + 2 === 13
                ? selectedDate.getFullYear() + 1
                : selectedDate.getFullYear()
            }`,
            value: nextMonthCounter
          }
        ];
        nextMonthCounter++;
      }
    }
  }

  const getPrevMonth = () => {
    if (prevRange !== undefined && counterPrev <= prevRange) {
      if (prevRange === counterPrev) {
        setPrevButtonDisabled(true);
        setNextButtonDisabled(false);
      } else {
        setNextButtonDisabled(false);
        setPrevButtonDisabled(false);
      }

      setCounterNext(counterNext - 1);
      setCounterPrev(counterPrev + 1);

      setSelectedDate(
        (prevValue) =>
          new Date(prevValue.getFullYear(), prevValue.getMonth() - 1, 1)
      );
    }

    if (prevRange === undefined) {
      setSelectedDate(
        (prevValue) =>
          new Date(prevValue.getFullYear(), prevValue.getMonth() - 1, 1)
      );
    }
  };

  const getNextMonth = () => {
    if (nextRange !== undefined && counterNext <= nextRange) {
      if (nextRange === counterNext) {
        setNextButtonDisabled(true);
        setPrevButtonDisabled(false);
      } else {
        setPrevButtonDisabled(false);
        setNextButtonDisabled(false);
      }

      setCounterPrev(counterPrev - 1);
      setCounterNext(counterNext + 1);

      setSelectedDate(
        (prevValue) =>
          new Date(prevValue.getFullYear(), prevValue.getMonth() + 1, 1)
      );
    }

    if (nextRange === undefined) {
      setSelectedDate(
        (prevValue) =>
          new Date(prevValue.getFullYear(), prevValue.getMonth() + 1, 1)
      );
    }
  };

  return {
    daysShort,
    monthNames,
    today,
    todayFormatted,
    calendarRows,
    selectedDate,
    getPrevMonth,
    getNextMonth,
    prevButtonDisabled,
    nextButtonDisabled
  };
};

export default useCalendar;
