import React from 'react';

import { DotsContent, DotTyping, DotCheck } from './InterUILoadingDots.styles';
import { IInterUILoadingDotsProps } from '../../interfaces/inter-ui-loading-dots-props';

/**
 * Componente Inter UI Loading.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUILoadingDots: React.FC<IInterUILoadingDotsProps> = ({
  bgColor,
  colorDots,
  checkmarkColor,
  dotLeft,
  dotCenter,
  dotRight,
  widthDot,
  heightDot,
  heightAnimation,
  checkmarkTop,
  isLoading = false,
  ...props
}) => {
  return (
    <DotsContent
      bgColor={bgColor}
      data-testid='content-loading-dots'
      {...props}
    >
      {isLoading ? (
        <DotTyping
          colorDots={colorDots}
          width={widthDot}
          height={heightDot}
          dotLeft={dotLeft}
          dotCenter={dotCenter}
          dotRight={dotRight}
          heightAnimation={heightAnimation}
        />
      ) : (
        <DotCheck checkmarkColor={checkmarkColor} checkmarkTop={checkmarkTop} />
      )}
    </DotsContent>
  );
};
