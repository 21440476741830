import React, { PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';

import { IInterUITimelineProps } from '../../interfaces/inter-ui-timeline-props';
import * as S from './InterUITimeline.styles';

export const TimelineItem: React.FC<
  PropsWithChildren<IInterUITimelineProps>
> = ({
  title,
  icon,
  type = 'Success',
  hasVerticalBar = false,
  children,
  ...attrs
}) => {
    const theme = useTheme();

    return (
      <S.TimelineItemContainer {...attrs}>
        <div style={{ minWidth: '20px' }}>
          {icon ? (
            <S.CustomIconContainer type={type}>{icon}</S.CustomIconContainer>
          ) : (
            <S.Dot type={type} />
          )}
          {hasVerticalBar ? <S.VerticalBar type={type} /> : ''}
        </div>

        <div style={{ paddingLeft: '12px' }}>
          {title && (
            <S.Text
              color={
                type === 'Disabled'
                  ? theme.colors.neutral.grayscale.A300
                  : theme.colors.neutral.grayscale.A500
              }
              bold
            >
              {title}
            </S.Text>
          )}

          <S.Text
            color={
              type === 'Disabled'
                ? theme.colors.neutral.grayscale.A300
                : theme.colors.neutral.grayscale.A400
            }
          >
            <p
              style={
                children ? { margin: '4.5px 0 14px' } : { margin: '3.5px 0 0' }
              }
            >
              {children}
            </p>
          </S.Text>
        </div>
      </S.TimelineItemContainer>
    );
  };
