import React, { ReactElement, useEffect, useState } from 'react';
import { IInterUISegmentControlProps } from '../../interfaces/inter-ui-segment-control-props';
import {
  Container,
  Icon,
  LabelPrimary,
  Labels,
  LabelSecondary
} from './InterUISegmentControl.styles';

export const InterUISegmentControl: React.FC<
  IInterUISegmentControlProps & React.HTMLAttributes<HTMLDivElement>
> = ({ children, selectedLabel, variant, margin }) => {
  const [activeLabel, setActiveLabel] = useState(
    selectedLabel || children[0].props['data-label']
  );

  useEffect(() => {
    if (selectedLabel) {
      setActiveLabel(selectedLabel);
    }
  }, [selectedLabel]);

  const renderLabelVariant = {
    primary: (child: ReactElement, idx: number) => (
      <LabelPrimary
        isActive={child.props['data-label'] === activeLabel}
        key={idx}
        onClick={() => setActiveLabel(child.props['data-label'])}
      >
        {child.props['data-label']}
      </LabelPrimary>
    ),
    secondary: (child: ReactElement, idx: number) => (
      <LabelSecondary
        isActive={child.props['data-label'] === activeLabel}
        key={idx}
        onClick={() => setActiveLabel(child.props['data-label'])}
      >
        {child.props['data-label']}
      </LabelSecondary>
    ),
    witchIcon: (child: ReactElement, idx: number) => (
      <LabelSecondary
        isActive={child.props['data-label'] === activeLabel}
        key={idx}
        onClick={() => setActiveLabel(child.props['data-label'])}
      >
        <Icon data-testid={`inter-ui-segment-icon-${idx}`}>
          {child.props['data-icon']}
        </Icon>
        {child.props['data-label']}
      </LabelSecondary>
    )
  };

  const render = renderLabelVariant[variant || 'primary'];

  return (
    <Container>
      <Labels variant={variant} margin={margin}>
        {children.map((child, idx) => render(child, idx))}
      </Labels>
      {children.map((child) =>
        child.props['data-label'] === activeLabel ? child : null
      )}
    </Container>
  );
};
