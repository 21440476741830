import { commonDark, commonLight, ICommonColors } from "./common";
import { grayscaleDark, grayscaleLight, IGrayscaleColors } from "./grayscale";


import { IPrimaryColors, primaryDark, primaryDarkPJ, primaryLight, primaryLightPJ } from "./primary";
import { IThemeColors, themeDark, themeLight } from "./theme";

export interface IFullColors {
  A500: string
  A400: string
  A300: string
  A200: string
  A100: string
}

export interface IHalfColors {
  A500: string
  A100: string
}

const themeColors: { [theme: string]: IThemeColors } = {
  LIGHT: {
    ...themeLight
  },
  DARK: {
    ...themeDark
  },
  LIGHT_PJ: {
    ...themeLight
  },
  DARK_PJ: {
    ...themeDark
  }
};

const primaryColors: { [theme: string]: IPrimaryColors } = {
  LIGHT: {
    ...primaryLight
  },
  DARK: {
    ...primaryDark
  },
  LIGHT_PJ: {
    ...primaryLightPJ
  },
  DARK_PJ: {
    ...primaryDarkPJ
  }
};

const grayscaleColors: { [theme: string]: IGrayscaleColors } = {
  LIGHT: {
    ...grayscaleLight
  },
  DARK: {
    ...grayscaleDark
  },
  LIGHT_PJ: {
    ...grayscaleLight
  },
  DARK_PJ: {
    ...grayscaleDark
  }
};

const commonColors: { [theme: string]: ICommonColors } = {
  LIGHT: {
    ...commonLight
  },
  DARK: {
    ...commonDark
  },
  LIGHT_PJ: {
    ...commonLight
  },
  DARK_PJ: {
    ...commonDark
  }
};

export const InterTheme = (theme: string) => {
  const themeColor = {
    title: theme.toLowerCase(),
    name: theme,
    colors: {

      brand: {
        orange: '#FF7A00',
      },

      base: {
        white: '#FFFFFF',
        black: '#161616'
      },

      account: {
        black: '#212121',
        win: '#323747',
        one: '#EFEEED'
      },

      ...primaryColors[theme],
      ...themeColors[theme],
      ...grayscaleColors[theme],
      ...commonColors[theme],

    }
  };

  return themeColor
};



