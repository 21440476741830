import styled, { css } from 'styled-components';

import { IInterUISeparatorProps } from '../../interfaces/inter-ui-separator-props';

/**
 * Div principal
 */
export const Separator = styled.div<IInterUISeparatorProps>`
  width: 100%;
  height: ${(props) => props.height || `8px`};
  ${(props) => props.margin && `margin: ${props.margin};`}
  background-color: ${(props) =>
    props.variant === 'small'
      ? props.theme.colors.base.white
      : props.theme.colors.neutral.grayscale.A100};

  ${(props) => props.variant === 'small' && BorderDashed}
`;

/**
 * Variação do separador com borda pontilhada.
 */
const BorderDashed = css`
  height: 1px;
  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.colors.neutral.grayscale.A200} 50%,
    ${(props) => props.theme.colors.theme} 0%
  );
  background-position: bottom;
  background-size: 12px 1px;
  background-repeat: repeat-x;
`;
