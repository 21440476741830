import styled from 'styled-components'
import { interGridBreakpoints } from '../../styles/utils/variables'

export const SocialNetwork = styled.div`

  ul {
    align-items: center;
    display: flex;
    justify-content: space-around;

    @media (min-width: ${interGridBreakpoints.md}) {
      margin-top: 28px;
    }

    @media (min-width: ${interGridBreakpoints.lg}) {
      align-items: initial;
      justify-content: initial;
      margin-top: 15px;
    }
    .list-inline-item:not(:last-child) {

      @media (min-width: ${interGridBreakpoints.md}) {
        margin-right: 46px;
      }
      
      @media (min-width: ${interGridBreakpoints.lg}) {
        margin-right: 18px;
      }     
    }
  }

  p {
    display: none;
  }

  svg {
    animation: ease-in-out 0.5s all;
    display: inline-block;
    width: 22px;
    height: 22px;

    &.colored{
      border-radius: 100%;
    }
  }
`
