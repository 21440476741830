import { css } from 'styled-components';

/**
 * Position absolute para alinhar elemento a esquerda e a direita, utilizando
 * styled components.
 *
 * @param className Classe css do elemento a ser posicionado.
 *
 * @package styled-components
 */
export const PositionAbsoluteLeftRight = (className: string) => css`
  ${`.${className}`} {
    position: absolute;
    top: 16px;

    &.left {
      left: 16px;
    }

    &.right {
      right: 16px;
    }
  }
`;

/**
 * Remove as acentuações da string.
 *
 * @param data String a ser removida a acentuação.
 * @param lowercase Indicador para informar se deseja transformar em lowercase.
 * @returns String sem acentuação.
 */
export const RemoveAccents = (data: string, lowercase = true) => {
  try {
    if (lowercase) {
      data = data.toLowerCase();
    }

    return data
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  } catch {
    return data;
  }
};

/**
 * Adiciona uma máscara monetária de acordo com o padrão de formatação informado.
 *
 * @param value Valor a ser formatado.
 * @param locale Padrão para formatação ('pt-BR' para formatação padrão Brasil).
 * @param fractionDigits Quantidade de casas decimais mínimas.
 *
 * @returns O valor formatado em string no padrão específico.
 *
 * @example
 * formatCurrency(123, 2, 'pt-BR');
 */
export const formatCurrency = (
  value: number,
  fractionDigits = 2,
  locale = 'pt-BR'
): string => {
  try {
    return Intl.NumberFormat(locale, {
      minimumFractionDigits: fractionDigits
    }).format(value);
  } catch {
    return value.toString();
  }
};

/**
 * Aplica a máscara monetária no elemento input informado.
 *
 * @param inputEl Referência do elemento input em que a máscara será aplicada.
 * @param value Valor a ser aplicado a formatação.
 * @param locale Padrão para formatação ('pt-BR' para formatação padrão Brasil).
 *
 * @returns void
 *
 * @example
 * currencyMask(inputEl.current, e.target.value, 'pt-BR');
 */
export const currencyMask = (
  inputEl: HTMLInputElement,
  value: string,
  fractionDigits = 2,
  locale = 'pt-BR'
): void => {
  try {
    inputEl.value = formatCurrency(
      parseInt(value.replace(/[\D]+/g, '')) / 100,
      fractionDigits,
      locale
    );

    if (inputEl.value === 'NaN') {
      inputEl.value = '';
    }
  } catch (error) {
    console.error('Error applying formatting to input', error);
  }
};
