import React from 'react';

import { Svg, HalfCircle, FullCircle } from './InterUILoading.styles';
import { IInterUILoadingProps } from '../../interfaces/inter-ui-loading-props';

/**
 * Componente Inter UI Loading.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUILoading: React.FC<IInterUILoadingProps> = ({
  size = 'sm',
  strokeWidth,
  fullColor,
  halfColor,
  ...props
}) => {
  const getSizePx = () => {
    switch (size) {
      case 'md':
        return '24px';
      case 'ld':
        return '32px';
      case 'xl':
        return '72px';
      case 'xs':
        return '10px';
      default:
        return '16px';
    }
  };

  return (
    <React.Fragment>
      <Svg
        width={getSizePx()}
        height={getSizePx()}
        viewBox='0 0 66 66'
        xmlns='http://www.w3.org/2000/svg'
        data-testid='content-loading'
        {...props}
      >
        <title>Loading representando o carregamento da página</title>
        <FullCircle
          fill='none'
          stroke-linecap='round'
          cx='33'
          cy='33'
          r='30'
          strokeWidth={strokeWidth}
          fullColor={fullColor}
        />
        <HalfCircle
          fill='none'
          stroke-linecap='round'
          cx='33'
          cy='33'
          r='30'
          strokeWidth={strokeWidth}
          halfColor={halfColor}
        />
      </Svg>
    </React.Fragment>
  );
};
