import React, { memo, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'

import { SocialNetwork } from './style'
import { orange } from '../../styles/utils/variables'

import SocialNetworkJson from './SocialNetwork.json'

import {
  ISocialNetworkProps,
  ISocialNetworkItem,
} from '../../Footer.interface'

import { theme } from '../../style'
import * as Icons from '../Icons'

const SocialNetworkComponent: React.FC<ISocialNetworkProps> = ({
  usedTheme,
  type,
  title,
  sendDatalayerFooterEvent,
}: ISocialNetworkProps): React.ReactElement => {
  const [ isActive, setIsActive ] = useState(-1)

  const [ iconColor, setIconColor ] = useState(theme.light.color.default)
  const iconActiveColor = orange.dark

  useEffect(() => {
    if (usedTheme) {
      setIconColor(theme[usedTheme].color.default)
    }
  }, [ usedTheme ])

  return (
    <SocialNetwork>
      <ul className='list-inline mb-0'>
        {SocialNetworkJson.map((item: ISocialNetworkItem, index: number) => {
          const NormalizedIcon = Icons[item.icon as keyof typeof Icons]
          return (
            <li key={'li--' + index} className='list-inline-item'>
              <a
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: title,
                    element_action: 'click button',
                    element_name: item.title,
                    redirect_url: item.link,
                    c_page: window.location.href,
                  })
                }}
                key={'item--' + index}
                href={item.link}
                target='_blank'
                rel='noopener noreferrer'
                title={item.title}
              >
                <span className='sr-only'>{item.name}</span>
                <NormalizedIcon
                  color={isActive === index ? iconActiveColor : iconColor}
                  className={type}
                  onMouseEnter={() => setIsActive(index)}
                  onMouseLeave={() => setIsActive(-1)}
                />
                <p className='fs-footer-16 fw-footer-300 ml-3 text-footer-gray--light'>
                  {item.slug}
                </p>
              </a>
            </li>
          )
        })}
      </ul>
    </SocialNetwork>
  )
}

export default withTranslation('Footer')(memo(SocialNetworkComponent))
