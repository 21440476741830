import { IFullColors } from "./inter"

export interface IGrayscaleColors {
  neutral: {
    grayscale: IFullColors
  }
}

export const grayscaleLight: IGrayscaleColors = {
  neutral: {
    grayscale: {
      A500: '#161616',
      A400: '#6A6C72',
      A300: '#B6B7BB',
      A200: '#DEDFE4',
      A100: '#F5F6FA'
    },
  }
}

export const grayscaleDark: IGrayscaleColors = {
  neutral: {
    grayscale: {
      A500: '#F0F1F6',
      A400: '#B6B7BB',
      A300: '#616368',
      A200: '#353539',
      A100: '#252628'
    },
  }
}


