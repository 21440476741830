import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  border-radius: 8px;
  flex-direction: column;
`;

export const Labels = styled.div<{ variant?: string; margin?: string }>`
  display: flex;
  flex-direction: row;
  ${(props) => props.margin && `margin: ${props.margin};`}
  padding: ${(props) => (props.variant === 'witchIcon' ? '6px 10px' : '0')};
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.neutral.grayscale.A200};
`;

export const Label = styled.div<{ isActive: boolean; variant?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-weight: bold;
  cursor: pointer;
  margin: 4px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  font-size: 0.75rem;
  transition: all 0.2s;
  -webkit-tap-highlight-color: transparent;
`;

export const LabelPrimary = styled(Label)`
  color: ${(props) =>
    props.isActive
      ? props.theme.colors.base.white
      : props.theme.colors.neutral.grayscale.A500};

  ${(props) =>
    props.isActive && `background-color: ${props.theme.colors.primary.A500};`}
`;

export const LabelSecondary = styled(Label)`
  color: ${(props) =>
    props.isActive
      ? props.theme.colors.primary.A500
      : props.theme.colors.neutral.grayscale.A500};

  ${(props) =>
    props.isActive && `background-color: ${props.theme.colors.primary.A100};`}
`;

export const Icon = styled.div`
  margin-right: 10px;
`;
