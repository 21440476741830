import InterRegularTTF from '../../assets/fonts/Inter/Inter-Regular.ttf';
import InterMediumTTF from '../../assets/fonts/Inter/Inter-Medium.ttf';
import InterSemiBoldTTF from '../../assets/fonts/Inter/Inter-SemiBold.ttf';
import InterBoldTTF from '../../assets/fonts/Inter/Inter-Bold.ttf';

import SoraRegularTTF from '../../assets/fonts/Sora/Sora-Regular.ttf';
import SoraMediumTTF from '../../assets/fonts/Sora/Sora-Medium.ttf';
import SoraSemiBoldTTF from '../../assets/fonts/Sora/Sora-SemiBold.ttf';
import SoraBoldTTF from '../../assets/fonts/Sora/Sora-Bold.ttf';

import InterFontIconsEOT from '../../assets/fonts/InterFontIcons/InterFontIcons.eot';
import InterFontIconsTTF from '../../assets/fonts/InterFontIcons/InterFontIcons.ttf';
import InterFontIconsWOFF from '../../assets/fonts/InterFontIcons/InterFontIcons.woff';
import InterFontIconsSVG from '../../assets/fonts/InterFontIcons/InterFontIcons.svg';

/**
 * Adiciona as fontes via javascript no head do HTML.
 *
 * @summary Se faz necessário incluir as fontes dessa forma, pois ao incluir através
 * do Styled Components (createGlobalStyle) o mesmo realiza um novo request para
 * carregamento das fontes ao ocorrer uma mudança no state da aplicação.
 */
export const InterUIFonts = () => {
  const style = document.createElement('style');

  style.textContent = FontFace;

  document.head.appendChild(style);
};

const FontFace = `
  @font-face {
    font-family: 'Inter';
    src: url(${InterRegularTTF});
    src: url(${InterRegularTTF}) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterMediumTTF});
    src: url(${InterMediumTTF}) format('truetype');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterSemiBoldTTF});
    src: url(${InterSemiBoldTTF}) format('truetype');
    font-weight: 600;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterBoldTTF});
    src: url(${InterBoldTTF}) format('truetype');
    font-weight: 700;
  }

  @font-face {
    font-family: 'Sora';
    src: url(${SoraRegularTTF});
    src: url(${SoraRegularTTF}) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Sora';
    src: url(${SoraMediumTTF});
    src: url(${SoraMediumTTF}) format('truetype');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Sora';
    src: url(${SoraSemiBoldTTF});
    src: url(${SoraSemiBoldTTF}) format('truetype');
    font-weight: 600;
  }

  @font-face {
    font-family: 'Sora';
    src: url(${SoraBoldTTF});
    src: url(${SoraBoldTTF}) format('truetype');
    font-weight: 700;
  }

  @font-face {
    font-family: 'InterFontIcons';
    src: url(${InterFontIconsEOT});
    src: url(${InterFontIconsEOT}) format('embedded-opentype'),
      url(${InterFontIconsTTF}) format('truetype'),
      url(${InterFontIconsWOFF}) format('woff'),
      url(${InterFontIconsSVG}) format('svg');
  }
`;
