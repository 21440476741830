import React from 'react'
import { withTranslation } from 'react-i18next'

import Logo from 'inter-frontend-svgs/lib/logos/banco-color'
import LogoBlog from 'inter-frontend-svgs/lib/logos/conta-com-a-gente-mono'
import PlayStore from 'inter-frontend-svgs/lib/stores/play-store-mono-pt'
import AppStore from 'inter-frontend-svgs/lib/stores/app-store-mono-pt'

import SocialNetwork from '../SocialNetwork'
import Link from '../Link'
import { FooterLanding } from './style'
import { ITranslateLang } from '../../Footer.interface'

/**
 * Função que gera uma landing page contendo o conteúdo do Footer.
 * @param t TLandingPageFooterProps
 */
const FooterLandingComponent: React.FC<ITranslateLang> = ({ t }: ITranslateLang): React.ReactElement => {
  return (
    <FooterLanding>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 col-md-3 col-lg-5 mt-4'>
            <div className='col text-center text-md-left pb-4'>
              <Link to='/' title='Banco Inter'>
                <Logo width='136' height='48' />
                <h1 className='sr-only'>Banco Inter</h1>
              </Link>
            </div>
          </div>
          <div className='col-sm-12 col-md-5 col-lg-4 pb-4'>
            <h2 className='fs-footer-18 mb-3 fw-footer-700 text-center text-md-left text-footer-gray--base'>{t('know_app')}</h2>
            <div className='row'>
              <div className='col text-center text-md-left'>
                <a
                  href='https://itunes.apple.com/br/app/banco-inter/id839711154'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='pr-3'
                  title='Baixe na App Store'
                >
                  <AppStore width='114' height='39' color='gray' />
                </a>
                <a
                  href='https://play.google.com/store/apps/details?id=br.com.intermedium'
                  target='_blank'
                  rel='noopener noreferrer'
                  title='Baixe no Google Play'
                >
                  <PlayStore width='126' height='39' color='gray' />
                </a>
              </div>
            </div>
          </div>
          <div className='col-sm-12 col-md-4 col-lg-3'>
            <h2 className='fs-footer-18 mb-3 fw-footer-700 text-center text-md-left text-footer-gray--base'>{t('our_blog')}</h2>
            <div className='row'>
              <div className='col text-center text-md-left'>
                <a
                  href='https://www.contacomagente.com.br/?utm_source=site&utm_medium=linklp'
                  target='_blank'
                  rel='noopener noreferrer'
                  title='O Blog da sua conta digital'
                >
                  <LogoBlog width='226' height='40' color='gray' />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='row border-top mt-4 py-4 0'>
          <div className='col-12 col-md-4 text-center text-md-right'>
            <div className='pl-2'><SocialNetwork color='orange' showTitle={false} /></div>
          </div>
          <div className='col-12 col-md-8 text-center text-md-right px-5 px-md-3 px-lg-5'>
            <address className='fs-footer-14 mt-3'>{t('address')}</address>
          </div>
        </div>
      </div>
    </FooterLanding>
  )
}

export default withTranslation('Footer')(FooterLandingComponent)
