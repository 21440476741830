import React from 'react';

import { IInterUIHeaderProps } from '../../interfaces/inter-ui-header-props';
import { Container, MainTitle, TextSmall } from './InterUIHeader.styles';

/**
 * Componente Inter UI Header.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUIHeader: React.FC<
  IInterUIHeaderProps & React.HtmlHTMLAttributes<HTMLElement>
> = ({ smallText, iconLeft, iconRight, ...props }) => {
  return (
    <Container data-testid='inter-ui-header' {...props}>
      <div className='inter-ui-header__btn-circle left'>{iconLeft}</div>

      <TextSmall {...props}>{smallText}</TextSmall>
      <MainTitle {...props}>{props.mainTitle}</MainTitle>

      <div className='inter-ui-header__btn-circle right'>{iconRight}</div>
    </Container>
  );
};
