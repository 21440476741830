export const ACCEPTED_FILE_TYPES = [
  '.png',
  '.msword', // word .doc
  '.vnd.openxmlformats-officedocument.wordprocessingml.document', // word .docx
  '.vnd.ms-excel', // excel xls
  '.vnd.openxmlformats-officedocument.spreadsheetml.sheet', // excel .xlsx
  '.jpg,',
  '.jpeg',
  '.pdf',
  '.docx',
  '.doc'
];
