/* eslint-disable import/no-named-default */
import React, { useEffect, useState } from 'react'

import ChevronDown from '@interco/icons/orangeds/LG/chevron-down'
import Check from '@interco/icons/orangeds/LG/check'

import { IconBrazil } from './assets/icon-brazil'
import { IconUSA } from './assets/icon-usa'

import { Country, OpenLang } from './style'
import { withTranslation } from 'react-i18next'

interface IFooterProps {
  t: (values: string) => string;
  theme?: string;
  ptURL: string;
  enURL: string;
  lang: string;
}

type ListProps = {
  country: string;
  icon?: React.ReactNode;
  urlPage: string;
}

const listLang = [
  {
    country: 'Brasil',
    icon: IconBrazil,
    urlPage: '/',
  },
  {
    country: 'USA',
    icon: IconUSA,
    urlPage: 'https://inter.co/en/us/',
  },
]

const PagesLanguages = (props: IFooterProps) => {
  const [ openLang, setOpenLang ] = useState(false)
  const [ active, setActive ] = useState(true)
  const [ isIndex, setIsIndex ] = useState(props.lang === 'pt' ? 0 : 1)
  const [ isList, setIsList ] = useState(listLang[isIndex])

  const HandleClick = (index: number) => {
    setIsIndex(index)
    isIndex === index && setActive(true)
  }

  useEffect(() => {
    setIsList(isList)
  }, [ isIndex, props.lang, isList ])

  return (
    <>
      <Country
        theme={props.theme}
        className='d-flex align-items-lg-center justify-content-lg-between justify-content-xl-start flex-column flex-lg-row mb-4 mt-3 mt-md-0'
      >
        <div className='col-12 col-lg-8 col-xl-7 px-lg-0'>
          <p className='fs-14 lh-17 mb-0 mr-xl-4'>
            {props.t('country')}
          </p>
        </div>
        <div className='col-12 position-relative'>
          <div key={isList.country} className='d-flex align-items-center justify-content-start'>
            <div className='lang'>
              {isList.icon}
            </div>
            <span className='fs-16 lh-20 fw-600' onClick={() => setOpenLang(!openLang)}>
              {isList.country}
            </span>
            <div
              onClick={() => {
                setOpenLang(!openLang)
                setActive(!active)
                HandleClick(isIndex)
              }}
              className='cursor-pointer'
            >
              <ChevronDown height={24} width={24} color={props.theme === 'dark' ? '#FFFFFF' : '#161616'} />
            </div>
          </div>
          {openLang &&
            <OpenLang>
              {listLang.map((item: ListProps, index: number) => (
                <div key={item.country} className='col-12 px-0 d-flex align-items-center justify-content-between list'>
                  <div className='lang'>
                    {item.icon}
                  </div>
                  <a
                    href={item.urlPage}
                    onClick={() => {
                      setOpenLang(!openLang)
                      HandleClick(index)
                    }}
                  >
                    <span className={`fs-16 lh-20 fw-600 ${active && isIndex === index ? 'text-orange--extra' : ''}`}>{item.country}</span>
                  </a>
                  <Check height={24} width={24} color={active && isIndex === index ? '#ff7a00' : '#FFFFFF'} />
                </div>
              ))}
            </OpenLang>
          }
        </div>
      </Country>
    </>
  )
}

export default withTranslation('Footer')(PagesLanguages)
