export var WbHttpMethods;
(function (WbHttpMethods) {
    WbHttpMethods["GET"] = "GET";
    WbHttpMethods["POST"] = "POST";
    WbHttpMethods["PUT"] = "PUT";
    WbHttpMethods["DELETE"] = "DELETE";
})(WbHttpMethods || (WbHttpMethods = {}));
export var WbEnvironments;
(function (WbEnvironments) {
    WbEnvironments["ANDROID"] = "ANDROID";
    WbEnvironments["IOS"] = "IOS";
    WbEnvironments["BROWSER"] = "BROWSER";
})(WbEnvironments || (WbEnvironments = {}));
export var WbPermissions;
(function (WbPermissions) {
    WbPermissions["CAMERA"] = "CAMERA";
    WbPermissions["AUDIO"] = "AUDIO";
    WbPermissions["STORAGE"] = "STORAGE";
    WbPermissions["LOCATION"] = "LOCATION";
    WbPermissions["GEOLOCATION"] = "GEOLOCATION";
})(WbPermissions || (WbPermissions = {}));
