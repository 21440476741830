import React, { useEffect, useState } from 'react'
import { I18nextProvider, withTranslation } from 'react-i18next'

import FooterPT from './FooterPT'
import FooterEN from './FooterEN'
import createI18n from './components/i18n/i18n'
import FooterLanding from './components/FooterLanding'
import LinkContext from './contexts/link'

import GlobalStyles from './styles/global'
import { Footer, FooterContainer } from './style'

import { IFooterLanguageProps } from './Footer.interface'

/**
 * Função que gera o componente de Footer.
 * @param props ILanguageDetails
 */

const FooterSiteComponent: React.FC<IFooterLanguageProps> = (props: IFooterLanguageProps): React.ReactElement => {
  const [ contentType, setContentType ] = useState(<></>)
  const isEn: boolean = props.i18n.language === 'en'

  useEffect(() => {
    if (props.landing) {
      setContentType(<FooterLanding />)
    } else {
      setContentType(
        <Footer theme={props.theme}>
          <FooterContainer>
            {isEn
              ? <FooterEN {...props} isPT={!isEn} />
              : <FooterPT {...props} isPT={!isEn} />
            }
          </FooterContainer>
        </Footer>,
      )
    }
  }, [ props, isEn ])

  return (
    <>
      <GlobalStyles />
      <LinkContext.Provider value={props.gatsby}>
        {contentType}
      </LinkContext.Provider>
    </>
  )
}

const WrapperFooter = <P extends object>(Component: React.ComponentType<P>) => {
  return (props: P) => (
    <I18nextProvider i18n={createI18n}>
      <Component {...props as P} />
    </I18nextProvider>
  )
}

export default WrapperFooter(withTranslation('Footer')(FooterSiteComponent))

FooterSiteComponent.defaultProps = {
  gatsby: false,
  landing: false,
  theme: 'light',
}
