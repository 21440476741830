import styled from 'styled-components';

export const Box = styled.div`
  width: 100%;
  padding: 1rem 0;
`;

export const LabelBetween = styled.div`
  width: 100%;
  padding: 0.625rem 0;
  display: flex;
  justify-content: space-between;
`;

export const Input = styled.input<{ percent: number }>`
  -webkit-appearance: none; /* Limpando os estilos */
  width: 100%;
  background-color: ${(props) => props.theme.colors.theme};

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: ${(props) => props.theme.colors.base.white};
    border-radius: 60px;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.05),
      0px 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    height: 24px;
    width: 24px;
    margin-top: -8px;
  }

  &::-moz-range-thumb {
    background: ${(props) => props.theme.colors.base.white};
    border-radius: 60px;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.05),
      0px 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    height: 24px;
    width: 24px;
  }

  &::-ms-thumb {
    background: ${(props) => props.theme.colors.base.white};
    border-radius: 60px;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.05),
      0px 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    height: 24px;
    width: 24px;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    background: ${(props) =>
    `linear-gradient(90deg, ${props.theme.colors.primary.A500} ${props.percent}%, ${props.theme.colors.neutral.grayscale.A100} ${props.percent}%)`};
    mix-blend-mode: normal;
    border-radius: 0.5rem;
    height: 0.5rem;
    width: 100%;
  }
`;
