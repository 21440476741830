import styled, { css } from 'styled-components';

/**
 * Estilo para o checkbox simples.
 */
const Simple = css`
  flex-direction: row-reverse;
  padding: 0;
  border-bottom: none;
`;

/**
 * Div principal.
 */
export const Container = styled.label<{
  checked?: boolean;
  disabled?: boolean;
  variant?: string;
  margin?: string;
}>`
  display: flex;
  align-items: center;
  margin: ${(props) => props.margin || `0`};
  padding: 14px 4px 14px 0px;

  ${(props) =>
    props.variant === 'border-bottom' &&
    `border-bottom: 1px solid ${props.theme.colors.neutral.grayscale.A200};`}
  ${(props) =>
    props.variant === 'border-top' &&
    `border-top: 1px solid ${props.theme.colors.neutral.grayscale.A200};`}

  ${(props) =>
    props.variant === 'border-box-left' &&
    `flex-direction: row-reverse;
      border: 1px solid ${props.theme.colors.neutral.grayscale.A200};
      border-radius: 8px;
      padding: 16px 12px;`};

  ${(props) =>
    props.variant === 'border-box-right' &&
    `border: 1px solid ${props.theme.colors.neutral.grayscale.A200};
      border-radius: 8px;
      padding: 16px 12px;`};

  ${(props) => props.variant === 'simple' && Simple};
`;

/**
 * Label que receberá o children.
 */
export const LabelContent = styled.div<{
  disabled?: boolean;
  variant?: string;
}>`
  width: 100%;
  padding-right: 19px;

  ${(props) =>
    (props.variant === 'simple' || props.variant === 'border-box-left') &&
    `margin-left: 12px`};

  ${(props) =>
    props.disabled && `&, * { color: ${props.theme.colors.neutral.grayscale.A300} }`};
`;

/**
 * Div que receberá o StyledCheckbox e o HiddenCheckbox.
 */
export const CheckboxContent = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
`;

/**
 * Input checkbox.
 */
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;

  &:checked {
    + span {
      background-color: ${(props) => props.theme.colors.primary.A500};
      border-color: ${(props) => props.theme.colors.primary.A500};

      .inter-ic-check {
        &:before {
          opacity: 1;
        }
      }
    }
  }

  &:disabled {
    + span {
      border-color: ${(props) => props.theme.colors.neutral.grayscale.A200};
    }

    &:checked {
      + span {
        background-color: ${(props) => props.theme.colors.neutral.grayscale.A200};
      }
    }
  }
`;

/**
 * Span com formato de checkbox.
 */
export const StyledCheckbox = styled.span`
  box-sizing: unset;
  width: 14px;
  height: 14px;
  margin-right: 12px;
  background-color: ${(props) => props.theme.colors.theme};
  border: 2px solid ${(props) => props.theme.colors.neutral.grayscale.A300};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  .inter-ic-check {
    &:before {
      opacity: 0;
      font-weight: 600;
    }
  }
`;
