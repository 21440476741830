import styled from 'styled-components';
import { IInterUIOverlayProps } from '../../interfaces/inter-ui-overlay-props';

/**
 * Overlay utilizado para bloqueio da tela.
 */
export const OverlayBlock = styled.div<IInterUIOverlayProps>`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: ${(props) =>
    props.background ? props.background : props.theme.colors.opacity.A500};
  display: ${(props) => (props.show ? 'block' : 'none')};
`;
