import React from 'react';
import { IInterUISwitchProps } from '../../interfaces/inter-ui-switch-props';
import {
  InputCheckboxHidden,
  SwitchContainer,
  Switch,
  Container,
  Content
} from './InterUISwitch.styles';

type InputProps = IInterUISwitchProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;

export const InterUISwitch = React.forwardRef<HTMLInputElement, InputProps>(
  ({ type, margin, children, ref, justify, style, ...props }, register) => {
    return (
      <Container
        data-testid='inter-ui-switch'
        htmlFor={props.id}
        checked={props.checked}
        disabled={props.disabled}
        margin={margin}
        justify={justify}
        style={style}
      >
        <Content disabled={props.disabled}>{children}</Content>
        <SwitchContainer>
          <InputCheckboxHidden
            data-testid='inter-ui-switch-hidden'
            ref={register}
            {...props}
          />
          <Switch />
        </SwitchContainer>
      </Container>
    );
  }
);
