import React, { useCallback } from 'react';

import { IInterUISliderProps } from '../../interfaces/inter-ui-slider-props';
import { Box, Input, LabelBetween } from './InterUISlider.styles';
/**
 * Componente Inter UI Slider.
 * @param props Propriedades disponíveis para definição do layout.
 */

export const InterUISlider: React.FC<
  IInterUISliderProps & React.HtmlHTMLAttributes<HTMLElement>
> = ({ min, max, value, id, onChange, leftText, rightText, variant }) => {
  const calcBackgroundPercent = useCallback(
    (): number => ((value - min) * 100) / (max - min),
    [value]
  );

  return (
    <Box data-testid={`box-${id}`}>
      <Input
        type='range'
        min={min}
        max={max}
        value={value}
        id={id}
        onChange={onChange}
        percent={calcBackgroundPercent()}
        data-testid={`input-${id}`}
      />
      {variant === 'between' && (
        <LabelBetween data-testid={`label-${id}`}>
          {leftText}
          {rightText}
        </LabelBetween>
      )}
    </Box>
  );
};
