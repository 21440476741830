// Map colors
export const orange = {
  base: '#FF8700',
  light: '#FF9F33',
  dark: '#FF500F',
  extra: '#FF7A00',
}

export const gray = {
  100: '#f8f9fa',
  200: '#e9ecef',
  300: '#dee2e6',
  400: '#ced4da',
  500: '#adb5bd',
  600: '#6c757d',
  700: '#495057',
  800: '#343a40',
  900: '#212529',
  base: '#58595b',
  light: '#747684',
  dark: '#292929',
}

export const grayscale = {
  100: '#F5F6FA',
  200: '#DEDFE4',
  300: '#B6B7BB',
  400: '#6A6C72',
  500: '#161616',
}

export const white = '#FFFFFF'
export const black = '#000000'

// Grid
export const interGridBreakpoints = {
  xs: '320px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
}

// Map font weights
export const fontWeights = {
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
}

// Map font sizes
export const fontSizes = {
  10: '10px',
  12: '12px',
  13: '13px',
  14: '14px',
  15: '15px',
  16: '16px',
  18: '18px',
  20: '20px',
  22: '22px',
  24: '24px',
  26: '26px',
  28: '28px',
  30: '30px',
  32: '32px',
  40: '40px',
  42: '42px',
  50: '50px',
  60: '60px',
  84: '84px',
}
