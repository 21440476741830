import { css } from 'styled-components'

export function generateColorClasses (colorMap, colorName) {
  const { base, light, dark } = colorMap

  return (
    css`
      .text-footer-${colorName}--base   { color: ${base}; }
      .text-footer-${colorName}--light  { color: ${light}; }
      .text-footer-${colorName}--dark   { color: ${dark}; }
    `
  )
}
