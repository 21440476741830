import React from 'react';
import { useTheme } from 'styled-components';

import { IInterUINavbarProps } from '../../interfaces/inter-ui-navbar-props';
import {
  Container,
  Headline,
  Icon,
  InvisibleBlock
} from './InterUINavbar.styles';
import { InterUIIcon } from '../../core/InterUIIcon/InterUIIcon';

/**
 * Componente Inter UI Navbar.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUINavbar: React.FC<
  IInterUINavbarProps & React.HtmlHTMLAttributes<HTMLElement>
> = ({
  title,
  hideBack = false,
  onClickedBack,
  iconLeft,
  iconRight,
  ...props
}) => {
  const theme = useTheme();

  const primary = theme.colors.primary.A500;

  return (
    <React.Fragment>
      <Container {...props}>
        <Icon>
          {hideBack || iconLeft ? (
            iconLeft
          ) : (
            <button
              type='button'
              aria-label='Botão voltar'
              onClick={onClickedBack}
            >
              <InterUIIcon name='arrow-left' size='md' color={primary} />
            </button>
          )}
        </Icon>

        <Headline>{title}</Headline>

        <Icon>{iconRight}</Icon>
      </Container>

      <InvisibleBlock />
    </React.Fragment>
  );
};
