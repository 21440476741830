import { InterWebviewBridge } from 'core/InterWebviewBridge';
import { WbHttpActions, WbHttpMethods } from 'enums';
/**
 * Função que permite fazer requisições usando o CA
 */
const InterWbHttp = () => {
    const iWb = InterWebviewBridge.getInstance();
    return {
        /**
         * Executa um get usando a CA
         * @param endpoint - endereço da requisição
         * @param headers - Headers Record<string,sring>
         * @param options - IWHttpOptions
         * @returns IWbHttpData
         */
        get(endpoint, headers = {}, options = {}) {
            return iWb.execute({
                action: WbHttpActions.REQUEST_API,
                metadata: {
                    method: WbHttpMethods.GET,
                    endpoint,
                    headers,
                    options,
                    engine: 'CA',
                },
            });
        },
        /**
         * Executa um post usando a CA
         *
         * @param endpoint - endereço da requisição
         * @param headers - Headers Record<string,sring>
         * @param body - body da requisição Record<string,unknown>
         * @param options - IWHttpOptions
         * @returns IWbHttpData
         */
        post(endpoint, body = {}, headers = {}, options = {}) {
            return iWb.execute({
                action: WbHttpActions.REQUEST_API,
                metadata: {
                    method: WbHttpMethods.POST,
                    endpoint,
                    body,
                    headers,
                    options,
                    engine: 'CA',
                },
            });
        },
        /**
         * Executa um put usando a CA
         *
         * @param endpoint - endereço da requisição
         * @param headers - Headers Record<string,sring>
         * @param body - body da requisição Record<string,unknown>
         * @param options - IWHttpOptions
         * @returns IWbHttpData
         */
        put(endpoint, body = {}, headers = {}, options = {}) {
            return iWb.execute({
                action: WbHttpActions.REQUEST_API,
                metadata: {
                    method: WbHttpMethods.PUT,
                    endpoint,
                    body,
                    headers,
                    options,
                    engine: 'CA',
                },
            });
        },
        /**
         * Executa um delete usando a CA
         *
         * @param endpoint - endereço da requisição
         * @param headers - Headers Record<string,sring>
         * @param options - IWHttpOptions
         * @returns IWbHttpData
         */
        delete(endpoint, headers = {}, options = {}) {
            return iWb.execute({
                action: WbHttpActions.REQUEST_API,
                metadata: {
                    method: WbHttpMethods.DELETE,
                    endpoint,
                    headers,
                    options,
                    engine: 'CA',
                },
            });
        },
    };
};
export const interWbHttp = InterWbHttp();
